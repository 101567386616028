import 'leaflet';
import 'leaflet.markercluster';
import 'leaflet-search';
import '../src/js/leaflet.spin';
import 'leaflet-easybutton';
import 'leaflet-draw';
import 'Leaflet.ControlledBounds/src/Leaflet.ControlledBounds';
import 'leaflet.restoreview';
import 'jquery-ujs';
// import 'leaflet.vectorgrid';
import Routes from '../src/js/routes.js.erb';
import Icon from '../src/js/icons';
import btoa from 'btoa';
import shp from 'shpjs';
import '../src/js/accordion.js';

const L = window['L'];
var map, poiLayer;

function hideNotices() {
  if($('body').is('#waterranger-app')) {
    if($('.notice').length) {
      setTimeout(function(){ 
        $('.notice').fadeOut("fast",function() {
          $(this).remove();
        });
      }, 3000);
    }
  }
}

let humanize = function(property) {
  return property.replace(/_/g, ' ')
    .replace(/(\w+)/g, function(match) {
      return match.charAt(0).toUpperCase() + match.slice(1);
    });
};

$.fn.hasAnyClass = function() {
  var classes = arguments[0].split(" ");
  for (var i = 0; i < classes.length; i++) {
    if (this.hasClass(classes[i])) {
      return true;
    }
  }
  return false;
}

var hidePoi = function() {
  map.removeLayer(poiLayer);
  map.panToOffset(map.getCenter(), [0,0]);
}

var showPoi = function() {
  map.addLayer(poiLayer);
  const poiBounds = poiLayer.getBounds();
  map.fitBounds(poiBounds,{maxZoom: 10});
  map.panToOffset(poiBounds.getCenter(), [0, 80]);
}

var mapReady;
mapReady = function() {

  L.Map.prototype.panToOffset = function (latlng, offset, options) {
    var x = this.latLngToContainerPoint(latlng).x - offset[0]
    var y = this.latLngToContainerPoint(latlng).y - offset[1]
    var point = this.containerPointToLatLng([x, y])
    return this.setView(point, this._zoom, { pan: options })
  }

  L.Map.prototype.setViewOffset = function (latlng, offset, targetZoom) {
    var targetPoint = this.project(latlng, targetZoom).subtract(offset),
    targetLatLng = this.unproject(targetPoint, targetZoom);
    return this.setView(targetLatLng, targetZoom);
  }

  function centerLeafletMapOnMarker(leafletMap, leafletMarker) {
    var latLngs = [ leafletMarker.getLatLng() ];
    var markerBounds = L.latLngBounds(latLngs);
    var resizeTimer;
    leafletMap.fitBounds(markerBounds);
    $(window).on('orientationchange resize', function () {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(function() {
        leafletMap.panToOffset([latLngs[0].lat, latLngs[0].lng],[20,0],16);
      }, 250);
    });
  }

  var $body = $('body'),
      mapContainer = $('#map'),
      isHome =  $body.hasClass('site-index'),
      isMap =  $body.hasClass('map-index'),
      isUser = $body.hasClass('users-info'),
      isGroup = $body.hasClass('groups-show'),
      isGroupRegion = $body.hasClass('groups-edit_region'),
      isPoiMap = $body.hasClass('points_of_interest-show'),
      isStaticMap = $body.hasAnyClass('locations-show locations-issues locations-observations locations-comments observations-show issues-show'),
      isMobile = { 
        Android: function() { return navigator.userAgent.match(/Android/i); }, 
        BlackBerry: function() { return navigator.userAgent.match(/BlackBerry/i); }, 
        iOS: function() { return navigator.userAgent.match(/iPhone|iPad|iPod/i); },
        Opera: function() { return navigator.userAgent.match(/Opera Mini/i); }, 
        Windows: function() { return navigator.userAgent.match(/IEMobile/i); },
        isTablet: function() { return navigator.userAgent.match(/Tablet|iPad/i); },
        any: function() { return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows()); } 
      };

  // Set up our spin options
  var spinOpts = {
    lines: 9, 
    length: 10,
    color: '#0f2a47',
    zIndex: 1000
  }
  
  // Add the tile layers
  var accessToken = 'pk.eyJ1Ijoid2F0ZXJyYW5nZXJzIiwiYSI6ImY4Mzc4MTZkZDZkN2Y4YzFhMjA2MzQ3NDAyZjM0MjI1In0.jA6aLxZWzUm8bSBbumka4Q';
  var tileMaps = new Object();

  // tileMaps["Outdoor"] = L.vectorGrid.protobuf('https://api.mapbox.com/styles/v1/waterrangers/cjl6itccb0tg92sm2jhrj31it/tiles/{z}/{x}/{y}@2x?access_token=' + accessToken, {
  //   attribution: '&copy; Mapbox-Outdoors',
  //   maxZoom: 16,
  //   minZoom: 3,
  //   vectorTileLayerStyles: {},
  // });

  tileMaps["Outdoor"] = L.tileLayer('https://api.mapbox.com/styles/v1/waterrangers/cjl6itccb0tg92sm2jhrj31it/tiles/256/{z}/{x}/{y}@2x?access_token=' + accessToken, {attribution: '&copy; Mapbox-Outdoors', maxZoom: 16, minZoom: 3});
  tileMaps["Satellite"] = L.tileLayer('https://api.mapbox.com/styles/v1/waterrangers/cjl6izuyl0tc02rpi7uobch99/tiles/256/{z}/{x}/{y}@2x?access_token=' + accessToken, {attribution: '&copy; Mapbox-Satellite', maxZoom: 16, minZoom: 3});
  tileMaps["Topographic"] = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}', {attribution: '&copy; Esri-World Topographic Map', maxZoom: 16, minZoom: 3});
  
  var baseMaps = {
    "Topographic": tileMaps["Topographic"],
    "Outdoor": tileMaps["Outdoor"],
    "Satellite": tileMaps["Satellite"]
  };

  var currentLocationMarkerSVG = `
    <svg width="34px" height="37px" viewBox="0 0 34 37" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <defs>
        <filter x="-8.2%" y="-6.6%" width="116.4%" height="113.1%" filterUnits="objectBoundingBox" id="filter-1">
          <feGaussianBlur stdDeviation="0.558035714" in="SourceGraphic"></feGaussianBlur>
        </filter>
      </defs>
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g transform="translate(2.000000, 0.000000)">
          <path d="M20.2482379,38.4359571 C27.0531516,32.1237194 30.4556084,27.0613115 30.4556084,23.2487332 C30.4556084,17.5298659 25.885613,12.8938079 20.2482379,12.8938079 C14.6108628,12.8938079 10.0408674,17.5298659 10.0408674,23.2487332 C10.0408674,27.0613115 13.4433242,32.1237194 20.2482379,38.4359571 Z" id="Oval-2" fill="#343434" opacity="0.226052989" filter="url(#filter-1)" transform="translate(20.248238, 25.664882) rotate(22.000000) translate(-20.248238, -25.664882) "></path>
          <path d="M15,37 C25,28.287371 30,21.0929266 30,15.4166667 C30,6.90227677 23.2842712,0 15,0 C6.71572875,0 0,6.90227677 0,15.4166667 C0,21.0929266 5,28.287371 15,37 Z" id="Oval-2" fill="#D59229"></path>
          <g id="crosshair" transform="translate(6.000000, 6.000000)" stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
              <circle id="Oval" cx="9" cy="9" r="9"></circle>
              <path d="M18,9 L14.4,9" id="Shape"></path>
              <path d="M3.6,9 L0,9" id="Shape"></path>
              <path d="M9,3.6 L9,0" id="Shape"></path>
              <path d="M9,18 L9,14.4" id="Shape"></path>
          </g>
        </g>
      </g>
    </svg>
  `;
  var currentLocationMarkerUrl = 'data:image/svg+xml;base64,' + btoa(currentLocationMarkerSVG);
  var currentLocationMarker = L.icon({
    iconUrl: currentLocationMarkerUrl,
    iconSize: [34,37],
    iconAnchor: [17,37],
    popupAnchor: [0,-37]
  });

  var locationMarkerSVG = `
    <svg width="34px" height="37px" viewBox="0 0 34 37" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <defs>
        <filter x="-8.2%" y="-6.6%" width="116.4%" height="113.1%" filterUnits="objectBoundingBox" id="filter-1">
          <feGaussianBlur stdDeviation="0.558035714" in="SourceGraphic"></feGaussianBlur>
        </filter>
      </defs>
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g transform="translate(2.000000, 0.000000)">
          <path d="M20.2482379,38.4359571 C27.0531516,32.1237194 30.4556084,27.0613115 30.4556084,23.2487332 C30.4556084,17.5298659 25.885613,12.8938079 20.2482379,12.8938079 C14.6108628,12.8938079 10.0408674,17.5298659 10.0408674,23.2487332 C10.0408674,27.0613115 13.4433242,32.1237194 20.2482379,38.4359571 Z" id="Oval-2" fill="#343434" opacity="0.226052989" filter="url(#filter-1)" transform="translate(20.248238, 25.664882) rotate(22.000000) translate(-20.248238, -25.664882) "></path>
          <path d="M15,37 C25,28.287371 30,21.0929266 30,15.4166667 C30,6.90227677 23.2842712,0 15,0 C6.71572875,0 0,6.90227677 0,15.4166667 C0,21.0929266 5,28.287371 15,37 Z" id="Oval-2" fill="#246EC0"></path>
          <circle id="Oval" fill="#FFFFFF" cx="15" cy="15" r="8"></circle>
        </g>
      </g>
    </svg>
  `;
  var locationMarkerUrl = 'data:image/svg+xml;base64,' + btoa(locationMarkerSVG);
  var locationMarker = L.icon({
    iconUrl: locationMarkerUrl,
    iconSize: [34,37],
    iconAnchor: [17,37],
    popupAnchor: [0,-37]
  });

  var poiMarker = L.icon({
    iconUrl: '/assets/poi.png'
  });
  var poiDefinedMarker = L.icon({
    iconUrl: '/assets/poi_defined.png'
  });

  if((isMap || isUser || isGroup || isGroupRegion || isHome) && mapContainer.length) {

    // Set the initial baselayercookie
    // if (typeof $.cookie("waterrangers_map") === 'undefined'){;
    //   $.cookie("waterrangers_map", "Topographic");
    // }

    // Clear any existing map
    if (map !== undefined) {
      map.off();
      map.remove();
    }
    // Set the map
    map = L.map('map', {
      zoom: 10,
      scrollWheelZoom: false,
      zoomControl: false,
      layers: [tileMaps['' || "Outdoor"]],
      maxBounds: [[-90, -180], [90, 180]]
    });
  
    map.on('baselayerchange', function(e) {
      //$.cookie("waterrangers_map", e.name);
    });
    
    map.spin(true,spinOpts);

    // Check for local storage in Safari when safe browsing
    if (typeof localStorage === 'object') {
      try {
        localStorage.setItem('localStorage', 1);
        localStorage.removeItem('localStorage');
      } catch (e) {
        Storage.prototype._setItem = Storage.prototype.setItem;
        Storage.prototype.setItem = function() {};
        alert('Your web browser does not support storing settings locally. In Safari, the most common cause of this is using "Private Browsing Mode". Some settings may not save or some features may not work properly for you.');
      }
    }
    
    // Load the default view to start, on a mobile default to your current location
    if (!(isMobile.any() || isUser || isGroup || isGroupRegion || isHome)) {
      loadDefaultView();
    } else if(isMap) {
      map.locate();
    }

    // Once the location is found add a marker and hide the pre-loader
    map.on('locationfound', onLocationFound);
    map.on('locationerror', onLocationError);
    
    function loadDefaultView(){
      if (!map.restoreView()) {
        map.locate();
      }
      map.spin(false);
    }
    
    function onLocationFound(e) {
      var currentlat = e.latitude,
          currentlng = e.longitude;
      if(isMobile.any() && !isMobile.isTablet()) {
        map.setViewOffset([currentlat, currentlng],[0,80],16);
      } else {
        map.setViewOffset([currentlat, currentlng],[160,40],16);
      }
      map.spin(false);
      var locationMarker = L.marker([currentlat,currentlng], {icon: currentLocationMarker}).bindPopup(L.popup({closeButton:false}).setContent(
        '<div class="map-popup">' +
        '<h4>'+I18n.map.your_location+'</h4>' +
        '<strong><a href="' + Routes.new_location_path({lat: currentlat, lng: currentlng}) + '" class="button observation" data-turbolinks="false">'+I18n.map.create_location+'</a></strong>' +
        '<div class="tiny expanded button-group">' +
        '<a href="' + Routes.new_observation_path({lat: currentlat, lng: currentlng}) + '" class="" data-turbolinks="false">'+I18n.map.add_observation+'</a>' +
        //'<a href="' + Routes.new_issue_path({lat: currentlat, lng: currentlng}) + '" class="" data-turbolinks="false">'+I18n.map.report_issue+'</a>' +
        '</div>' +
        '<a href="#" class="leaflet-popup-close-button close-popup">x</a>' +
        '</div>'
      )).addTo(map).openPopup();
      closePopup();
    }
    
    function onLocationError(e) {
      alert(I18n.map.not_found);
      if(!map.restoreView()) {
        map.setView(new L.LatLng(45.4215, -75.6972), 10); // Lat & Lon are for Ottawa
      }
      map.spin(false);
    }

    // Remove both the location found marker and any clicked markers
    function removeMarkers() {
      if (typeof marker != 'undefined' && marker) {
        map.removeLayer(marker);
      }
      if (typeof locationMarker != 'undefined' && locationMarker) {
        map.removeLayer(locationMarker);
      }
    }
    
    // Add ability to click on a location and add an observation
    var marker;
    function onMapClick(e) {
      removeMarkers();
      var position = e.latlng;
      marker = L.marker(e.latlng, {icon: locationMarker, draggable: 'true'});
      marker.addTo(map).bindPopup(L.popup({
        closeButton:false
      }).setContent(
        '<div class="map-popup">' +
        '<h4>'+I18n.map.clicked_location+'</h4>' + 
        '<strong><a href="' + Routes.new_location_path({lat: position.lat, lng: position.lng}) + '" class="button observation" data-turbolinks="false">'+I18n.map.create_location+'</a></strong>' +
        '<div class="tiny expanded button-group">' +
        '<a href="' + Routes.new_observation_path({lat: position.lat, lng: position.lng}) + '" class="" data-turbolinks="false">'+I18n.map.add_observation+'</a>' +
        //'<a href="' + Routes.new_issue_path({lat: position.lat, lng: position.lng}) + '" class="" data-turbolinks="false">'+I18n.map.report_issue+'</a>' +
        '</div>' +
        '<a href="#" class="leaflet-popup-close-button close-popup">x</a>' +
        '</div>'
      )).openPopup();
      marker.on('dragend', function(event){
        var marker = event.target;
        var position = marker.getLatLng();
        marker.setLatLng(new L.LatLng(position.lat, position.lng),{draggable:'true'}).bindPopup(L.popup({
          closeButton:false
        }).setContent(
        '<div class="map-popup">' +
        '<h4>'+I18n.map.clicked_location+'</h4>' + 
        '<div class="tiny expanded button-group">' +
        '<a href="' + Routes.new_observation_path({lat: position.lat, lng: position.lng}) + '" class="" data-turbolinks="false">'+I18n.map.add_observation+'</a>' +
        //'<a href="' + Routes.new_issue_path({lat: position.lat, lng: position.lng}) + '" class="" data-turbolinks="false">'+I18n.map.report_issue+'</a>' +
        '</div>' +
        '<a href="#" class="leaflet-popup-close-button close-popup">x</a>' +
        '</div>'
      )).openPopup();
      });
      if (isMobile.any()) {
        map.panToOffset(new L.LatLng(position.lat, position.lng),[0,80]);
      } else {
        map.panTo(new L.LatLng(position.lat, position.lng))
      }
    }
    // Only needed on Group and Home maps
    if(!isGroupRegion) {
      map.on('click', onMapClick);
    }
    closePopup();

    // Do a custom close button due to a bug with onpopupclose
    function closePopup() {
      map.on('popupopen', function(e) {
        //removeMarkers();
        var currentMarker = e.popup._source;
        // if (isMobile.any()) {
        //   map.panToOffset(currentMarker._latlng,[0,100]);
        // }
        $('.close-popup').on('click', function(e){
          e.preventDefault();
          map.closePopup();
          map.removeLayer(currentMarker);
        });
      });
    }

    // Set up shared items between the base locations and filtered items

    var pointToLayer = function (feature, latlng) {

      var obsColor = '#46B64B', // Green for Observations
          issColor = '#D42626', // Red for Issue
          markerColor = feature.properties.is_observation ? obsColor : issColor;

      var markerSVG = `
        <svg width="34px" height="37px" viewBox="0 0 34 37" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <defs>
            <filter x="-8.2%" y="-6.6%" width="116.4%" height="113.1%" filterUnits="objectBoundingBox" id="filter-1">
              <feGaussianBlur stdDeviation="0.558035714" in="SourceGraphic"></feGaussianBlur>
            </filter>
          </defs>
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g transform="translate(2.000000, 0.000000)">
              <path d="M20.2482379,38.4359571 C27.0531516,32.1237194 30.4556084,27.0613115 30.4556084,23.2487332 C30.4556084,17.5298659 25.885613,12.8938079 20.2482379,12.8938079 C14.6108628,12.8938079 10.0408674,17.5298659 10.0408674,23.2487332 C10.0408674,27.0613115 13.4433242,32.1237194 20.2482379,38.4359571 Z" id="Oval-2" fill="#343434" opacity="0.226052989" filter="url(#filter-1)" transform="translate(20.248238, 25.664882) rotate(22.000000) translate(-20.248238, -25.664882) "></path>
              <path d="M15,37 C25,28.287371 30,21.0929266 30,15.4166667 C30,6.90227677 23.2842712,0 15,0 C6.71572875,0 0,6.90227677 0,15.4166667 C0,21.0929266 5,28.287371 15,37 Z" id="Oval-2" fill="${markerColor}"></path>
              <circle id="Oval" fill="#FFFFFF" cx="15" cy="15" r="8"></circle>
            </g>
          </g>
        </svg>
      `;

      var markerUrl = 'data:image/svg+xml;base64,' + window.btoa(markerSVG);

      var marker = L.icon({
        iconUrl: markerUrl,
        iconSize: [34,37],
        iconAnchor: [17,37],
        popupAnchor: [0,-37]
      });

      return new L.marker(latlng, {icon: marker})
    }

    var onEachFeature = function(feature, layer) {
      var url = (feature.properties.is_issue) ? Routes.issue_path(feature.properties.id) : Routes.observation_path(feature.properties.id);
      layer.bindPopup(
        '<div class="map-popup">' +
        ((typeof(feature.properties.body_of_water) !== 'undefined') && (feature.properties.body_of_water !== null) ? "<h4>" + feature.properties.body_of_water + "</h4>" : "") +
        ((typeof(feature.properties.name) !== 'undefined') && (feature.properties.name !== null) ? "<p><strong>" + feature.properties.name + "</strong></p>" : "") +
        '<a href="' + url + '" data-turbolinks="false">' + I18n.map.view_summary + '</a>' +
        '</div>'
      );
    }

    var pointToLayerPOI = function (feature, latlng) {
      var icon = (feature.properties.has_upstream && feature.properties.has_downstream) ? poiDefinedMarker : poiMarker;
      return new L.marker(latlng, { icon });
    }

    var onEachFeaturePOI = function(feature, layer) {
      layer.bindPopup(
        '<div class="map-popup">' +
        ((typeof(feature.properties.receiving_water_course) !== 'undefined') && (feature.properties.body_of_water !== null) ? "<h4>" + feature.properties.receiving_water_course + "</h4>" : "") +
        ((typeof(feature.properties.name) !== 'undefined') && (feature.properties.name !== null) ? "<p>" + feature.properties.name + "</p>" : "") +
        '<strong><a href="' + feature.properties.url + '" class="button observation" data-turbolinks="false">'+I18n.map.view_summary+'</a></strong>' +
        '</div>'
      );
    }

    var pointToLayerLocation = function (feature, latlng) {
      var obsCount = feature.properties.observations_count;
      var issCount = feature.properties.issues_count;

      var markerSVG = `
        <svg xmlns="http://www.w3.org/2000/svg" width="42" height="37" viewBox="0 0 42 37">
          <defs>
            <filter id="map-marker-2-a" width="115.8%" height="129.3%" x="-7.9%" y="-14.7%" filterUnits="objectBoundingBox">
              <feGaussianBlur in="SourceGraphic" stdDeviation="1.027"/>
            </filter>
          </defs>
          <path fill="#393939" d="M8.53548945,15 L39.788443,15 C40.8930125,15 41.788443,15.8954305 41.788443,17 C41.788443,17.0669344 41.7850829,17.1338267 41.7783752,17.2004242 L40.3683077,31.2004242 C40.2653941,32.2222124 39.4053334,33 38.3783755,33 L23.987056,33 L20.987056,36 L17.987056,33 L4.79208859,33 C3.68751909,33 2.79208859,32.1045695 2.79208859,31 C2.79208859,30.8255694 2.8149077,30.6518884 2.85996506,30.4833777 L6.60336592,16.4833777 C6.8372931,15.6085099 7.62988719,15 8.53548945,15 Z" filter="url(#map-marker-2-a)" opacity=".6"/>
          <path fill="#FFF" d="M2.00000089,2.22044605e-16 L35.9999176,0 C37.0994516,-2.01981102e-16 37.9927824,0.887586019 37.999876,1.98709704 L38.1740696,28.987097 C38.1811957,30.0916436 37.2915607,30.9928323 36.1870142,30.9999584 C36.1827132,30.9999861 36.1784122,31 36.1741112,31 L21.987056,31 L18.987056,34 L15.987056,31 L2.17419444,31 C1.07466053,31 0.181329681,30.112414 0.174236062,29.012903 L4.25135359e-05,2.01290296 C-0.007083593,0.908356445 0.882551422,0.00716772854 1.98709793,4.16220097e-05 C1.99139886,1.38740754e-05 1.99569987,4.44879294e-16 2.00000089,4.4408921e-16 Z"/>
          <g id="observations" transform="translate(1 1)">
            <rect width="36" height="29" fill="#46B64B" rx="1"/>
            <text x="18" y="14" dy="5" text-anchor="middle" fill="#FFF" font-family="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'" font-size="11" font-weight="bold">
              ${obsCount}
            </text>
          </g>
        </svg>
      `;

      var iconUrl = 'data:image/svg+xml;base64,' + window.btoa(markerSVG);

      var locationMarker = L.icon({
        iconUrl: iconUrl,
        iconSize: [42,37],
        iconAnchor: [21,37],
        popupAnchor: [0,-37]
        //popupAnchor: [-3, -76]
      });

      return new L.marker(latlng, {icon: locationMarker})
    }

    var onEachFeatureLocation = function(feature, layer) {
      layer.bindPopup(
        '<div class="map-popup">' +
        ((typeof(feature.properties.body_of_water) !== 'undefined') && (feature.properties.body_of_water !== null) ? "<h4>" + feature.properties.body_of_water + "</h4>" : "") +
        ((typeof(feature.properties.name) !== 'undefined') && (feature.properties.name !== null) ? "<p>" + feature.properties.name + "</p>" : "") +
        '<strong><a href="' + Routes.location_path(feature.properties.id) + '" class="button observation" data-turbolinks="false">'+I18n.map.view_summary+'</a></strong>' +
        '<div class="tiny expanded button-group">' +
        '<a href="' + Routes.new_observation_path({location: feature.properties.id}) + '" class="" data-turbolinks="false">'+I18n.map.add_observation+'</a>' +
        //'<a href="' + Routes.new_issue_path({location: feature.properties.id}) + '" class="" data-turbolinks="false">'+I18n.map.report_issue+'</a>' +
        '</div>' +
        '</div>'
      );
    }

    var style = function(feature) {
      // console.log(feature);
    }

    if(isHome) {

      const localObservationsIssues = new L.markerClusterGroup();
      $.ajax({
        // For now return all locations on homepage
        // url: Routes.local_observations_issues_path({format: 'json'}),
        url: Routes.locations_path({format: 'json'}),
        dataType: "json",
        beforeSend: function(){
          map.spin(true,spinOpts);
        },
        success: function (data) {
          console.log(data);
          const localObservationsIssuesJSON = new L.GeoJSON(data, {
            style: style,
            pointToLayer: pointToLayerLocation,
            onEachFeature: onEachFeatureLocation
          });
          localObservationsIssues.addLayer(localObservationsIssuesJSON);
          const markers = localObservationsIssues._needsClustering;
          if(markers.length === 0) {
            // alert('No locations found. Please try searching again.');
            map.spin(false);
          }
          if(gon.user_location_lat || gon.user_location_lng) {
            map.setView([gon.user_location_lat, gon.user_location_lng], 11);
          } else {
            map.fitBounds(localObservationsIssues.getBounds(),{ maxZoom: 14,paddingTopLeft:[500,0] });
          }
          map.addLayer(localObservationsIssues);
          $('.spinner').remove();
        }
      });
    }
    
    // The filterable map page with all locations
    if(isMap) {
      var body = $('body');

      var addFilteredData = function(data, values) {
        var filteredLocations = L.markerClusterGroup(),
            filteredLocationsJSON = new L.GeoJSON(data, {
          style: style,
          pointToLayer: pointToLayerLocation,
          onEachFeature: onEachFeatureLocation,
          filter: function(feature, layer) {
            if(values !== null) {
              return values.every(function(element) {
                return feature.properties[element.value];
              });
            }
          }
        });
        filteredLocations.addLayer(filteredLocationsJSON);
        var markers = filteredLocations._needsClustering;
        map.addLayer(filteredLocations);
        currentLayer = filteredLocations;
        if(markers.length === 0) {
          alert('No locations found. Please try searching again.');
          map.spin(false);
        } else {
          if (isMobile.any()) {
            map.fitBounds(filteredLocations.getBounds(),{maxZoom: 10});
          } else {
            map.fitBounds(filteredLocations.getBounds(),{maxZoom: 10,paddingTopLeft:[400,0]});
          }
        }
      }

      // Then we load the base locations map
      var currentLayer,
          allLocations = new L.markerClusterGroup(),
          groupRegionLayers = new L.FeatureGroup();
      $.ajax({
        url: Routes.locations_path({format: 'json'}),
        dataType: "json",
        beforeSend: function(){
          map.spin(true,spinOpts);
        },
        success: function (data) {
          var allLocationsJSON = new L.GeoJSON(data, {
            style: style,
            pointToLayer: pointToLayerLocation,
            onEachFeature: onEachFeatureLocation
          });
          allLocations.addLayer(allLocationsJSON);
          var markers = allLocations._needsClustering;
          if(markers.length === 0) {
            alert('No locations found. Please try searching again.');
            map.spin(false);
          }
          map.addLayer(allLocations);
          currentLayer = allLocations;
          map.spin(false);
          // if (isMobile.any()) {
          //   map.panToOffset(map.getCenter(),[0,0]);
          // } else {
          //   map.panToOffset(map.getCenter(),[200,0]);
          // }
          map.panToOffset(map.getCenter(),[0,0]);
        }
      });

      poiLayer = new L.FeatureGroup();
      $.ajax({
        url: Routes.poi_index_path({format: 'json'}),
        dataType: 'json',
        success: function(data) {
          const pointsOfInterestJSON = new L.GeoJSON(data, {
            style: style,
            pointToLayer: pointToLayerPOI,
            onEachFeature: onEachFeaturePOI
          });
          poiLayer.addLayer(pointsOfInterestJSON);
        }
      });

      $.ajax({
        url: Routes.map_path({format: 'json'}),
        dataType: "json",
        success: function (data) {
          // Create our filter sidebar
          var sidebarFilters = $(".map-sidebar-filters"),
              groupFilters = data.groups,
              issueFilters = data.issues,
              testFilters = data.tests,
              wildlifeFilters = data.wildlife,
              invasiveFilters = data.invasive_species;
          sidebarFilters.append(
            '<div class="map-filter-header"><h4 class="alt-header">'+I18n.filter.locations+'</h4> <strong>'+I18n.filter.show_me+'</strong><button class="reset-filters">'+I18n.filter.reset+'</button></div>' +
            '<div class="map-filters">' +
            '<div class="map-filter-list scroll-target">' +
            '<form action="#" method="get" accept-charset="utf-8" class="map-filters-form">' +
              '<div class="map-list">' +
                '<fieldset class="water-test-filters map-filter-group"><h3 class="map-filter-target">'+I18n.categories.water_quality_tests+'</h3><div class="map-filter-content"/></fieldset>' +
                '<fieldset class="wildlife-filters map-filter-group"><h3 class="map-filter-target">'+I18n.categories.wildlife+'</h3><div class="map-filter-content"/></fieldset>' +
                '<fieldset class="invasive-filters map-filter-group"><h3 class="map-filter-target">'+I18n.categories.invasive_species+'</h3><div class="map-filter-content"/></fieldset>' +
                '<fieldset class="issue-filters map-filter-group"><h3 class="map-filter-target">'+I18n.issue.multiple+'</h3><div class="map-filter-content"/></fieldset>' +
                '<fieldset class="group-filters map-filter-group"><h3 class="map-filter-target">'+I18n.group.groups+'</h3><div class="map-filter-content"/></fieldset>' +
              '</div>' +
            '</form>' +
            '<form action="#" method="get" accept-charset="utf-8">' +
              '<div class="map-filter-header"><h4 class="alt-header">'+I18n.filter.watersheds+' <a class="help-link ajax-modal" href="/help/watersheds">Whats this?</a></h4></div>' +
              '<div class="watershed-list">' +
                '<fieldset class="watershed-filters"><div class="map-filter-content">' +
                  '<label class="map-filter-label"><input type="checkbox" name="watershed" data-shpfile="ontario.zip"/> Ontario</label>' +
                  '<label class="map-filter-label"><input type="checkbox" name="watershed" data-shpfile="alabama.zip"/> Alabama</label>' +
                  '<label class="map-filter-label"><input type="checkbox" name="watershed" data-shpfile="world.zip"/> World</label>' +
                '</div></fieldset>' +
              '</div>' +
            '</form>' +
            '</div>' +
            '</div>'
          );
          for (var i = 0; i < groupFilters.length; i++) {
            var groupID = groupFilters[i][0],
                groupRegion = $.isEmptyObject(groupFilters[i][3]) ? "{}" : JSON.stringify(groupFilters[i][3]),
                groupNameSplit = groupFilters[i][1].split(" : "),
                groupName = groupNameSplit[0],
                groupProgram = (typeof groupNameSplit[1] != 'undefined') ? groupNameSplit[1] : I18n.map.no_program,
                value = groupFilters[i][2].replace(/[^a-z0-9]+/g,'_').replace(/(^-|-$)/g,'');
            $('<label class="map-filter-label"><input type="checkbox" id="filter" name="filter[group][]" value="'+value+'" data-id="'+groupID+'" data-region='+groupRegion+' data-name="'+groupName+'"><span>'+groupName+'</span>'+groupProgram+'</label>').appendTo($('.group-filters>.map-filter-content'));
          }
          for (var i = 0; i < testFilters.length; i++) {
            $('<label class="map-filter-label"><input type="checkbox" id="filter" name="filter[water_quality_tests][]" value="'+testFilters[i]+'">'+I18n.tests[testFilters[i]].name+'</label>').appendTo($('.water-test-filters>.map-filter-content'));
          }
          for (var i = 0; i < wildlifeFilters.length; i++) {
            var wildlifeName = wildlifeFilters[i].toLowerCase().replace(/ /g,"_")
            $('<label class="map-filter-label"><input type="checkbox" id="filter" name="filter[wildlife][]" value="'+wildlifeName+'">'+I18n.wildlife[wildlifeName]+'</label>').appendTo($('.wildlife-filters>.map-filter-content'));
          }
          for (var i = 0; i < invasiveFilters.length; i++) {
            var invasiveName = invasiveFilters[i].toLowerCase().replace(/ /g,"_")
            $('<label class="map-filter-label"><input type="checkbox" id="filter" name="filter[invasive_species][]" value="'+invasiveName+'">'+I18n.invasive_species[invasiveName]+'</label>').appendTo($('.invasive-filters>.map-filter-content'));
          }
          for(var issue in issueFilters){
            $('<label class="map-filter-label"><input type="checkbox"  id="filter" name="filter[issue][]" value="'+issue+'">'+I18n.issue.categories[issue]+'</label>').appendTo($('.issue-filters>.map-filter-content'));
          };
          $('<fieldset class="map-base-filters map-filter-group"><label class="map-filter-label"><input type="checkbox" id="filter" name="filter[has_observations]" value="has_observations">'+I18n.observation.multiple+'</label><label class="map-filter-label"><input type="checkbox" id="filter" name="filter[has_issues]" value="has_issues">'+I18n.issue.multiple+'</label></fieldset>').prependTo($('.map-list'));

          // Filter accordion
          $('.map-filter-list').accordion({
            item: ".map-filter-group",
            content: ".map-filter-content",
            target: ".map-filter-target",
            showFirstItem: true,
            autoCollpase: false,
            scrollTo: true
          });

          // On submit of the form load a filtered list of locations using 
          // geojson to filter the points based on properties
          $('.filter-submit').hide();
          //map.addLayer(groupRegionLayers);
          var submitCheckbox = $('input[id="filter"]'),
              watershedCheckbox = $('input[name="watershed"]'),
              polygonLayers = {},
              polygonLayer = new L.FeatureGroup(),
              watershedLayer = new L.LayerGroup,
              polygonStyle = {
                "color": "#1c3653",
                "weight": 3,
                "opacity": 0.3
              };
          // Add a layer to draw the group region polygon on if it exists
          map.addLayer(polygonLayer);
          map.addLayer(watershedLayer);
          // Use the change event of the filter inputs to filter the map
          submitCheckbox.on("change", function(){
            // Trigger submission of the form (see submit function below)
            $(this.form).trigger('submit');
            // Mobile doesn't need to keep the filter bar open as it covers the map
            if (isMobile.any()) {
              body.removeClass('has-filters');
            }
            // Add the region polygon if it exists
            // if(!map.hasLayer(polygonLayer)) {
            //   map.addLayer(polygonLayer);
            // }
            if($(this).attr('data-region')) {
              // Make the Group ones act like radio buttons as we can't merge that data
              // $('input[data-region]').not(this).prop('checked', false);
              var groupRegion = $(this).data('region'),
                  regionLayer = 'regionLayer'+$(this).data('id'),
                  regionName = $(this).data('name');
              // Only try and add it if the Object exists
              if(!$.isEmptyObject(groupRegion)) {
                //console.log(groupRegion);
                // Now we add a class to toggle the polygon
                if(!$(this).hasClass('region-added')) {
                  $(this).addClass('region-added');
                  polygonLayers[regionLayer] = L.geoJson(groupRegion,{
                    // Add a label on top of the polygon
                    onEachFeature: function(feature, layer) {
                      var label = L.marker(layer.getBounds().getCenter(), {
                        icon: L.divIcon({
                          className: 'polygon-title',
                          html: regionName,
                          iconSize: [100, 40]
                        })
                      }).addTo(map);
                    },
                    style: polygonStyle
                  });
                  polygonLayer.addLayer(polygonLayers[regionLayer]);
                } else {
                  $(this).removeClass('region-added');
                  polygonLayer.removeLayer(polygonLayers[regionLayer]);
                  delete polygonLayers[regionLayer];
                }
              }
            }
          });
          $(".map-filters-form").on("submit", function(e){
            e.preventDefault();
            // If the reset button is not already visible, show it
            if(!$('.reset-filters').is(':visible')) {
              $('.reset-filters').fadeIn();
            }
            // Remove the current layer & any searched or clicked layers
            map.removeLayer(currentLayer);
            removeMarkers();
            // Grab the form values
            const submittedValues = $(this).serializeArray();
            $.ajax({
              url: Routes.filtered_locations_path({format: 'json'}),
              data: submittedValues,
              dataType: "json",
              beforeSend: function(){
                submitCheckbox.prop('disabled', true);
                map.spin(true,spinOpts);
              },
              success: function (data) {
                addFilteredData(data, submittedValues);
                map.spin(false);
                submitCheckbox.prop('disabled', false);
              }
            });
          });

          // Load Watersheds for North America / Canada - TO REDO WITH POSTGIS
          // THIS IS A TOTAL HACK
          let shpGeoJson;
          watershedCheckbox.on("change", function(){
            $('input[name="watershed"]').not($(this)).prop('checked', false);
            $(this).prop("checked", $(this).prop("checked"));
            var shpFileName = $(this).data('shpfile');
            // Remove any existing shapefile layers
            if(map.hasLayer(shpGeoJson)) {
              map.removeLayer(shpGeoJson);
            }
            // If the reset button is not already visible, show it
            if(!$('.reset-filters').is(':visible')) {
              $('.reset-filters').fadeIn();
            }
            if(isMobile.any()) {
              body.removeClass('has-filters');
            }
            if($(this).prop('checked')) {
              map.spin(true,spinOpts);
              shp("shpfiles/"+shpFileName).then(function(data){
                shpGeoJson = L.geoJson(data,{
                  onEachFeature: function(feature, layer) {
                    if (feature.properties) {
                      // layer.bindPopup(Object.keys(feature.properties).map(function(k) {
                      //     if(k === '__color__'){
                      //       return;
                      //     }
                      //     return k + ": " + feature.properties[k];
                      // }).join("<br />"), {
                      //   maxHeight: 200
                      // });

                      // World & Alabama Shapes
                      if (feature.properties.NAME) {
                        layer.bindPopup(
                          '<div class="map-popup">' +
                          '<h4 class="watershed">' + feature.properties.NAME + '</h4>' +
                          ((feature.properties.NAME == "Mobile Bay") ? "<a href='/groups/mobile-baykeeper-mobile-baykeeper-team'>Mobile Bay Keeper</a><br><a href='/groups/mobile-baykeeper-citronelle-high-school'>Citronelle High School</a><br><a href='/groups/dog-river-dog-river-clearwater-revival'>Dog River Clear Water Revival</a><br><a href='/groups/fraca-fowl-river-area-civic-association'>FRACA</a><br><a href='/groups/alabama-little-lagoon-preservation-society'>Little Lagoon Preservation Society</a>" : "") +
                          ((feature.properties.NAME == "St. Lawrence") ? "<a href='/groups/ottawa-riverkeeper-riverwatch'>Ottawa Riverkeeper</a>" : "") +
                          '</div>'
                        );
                      // America Shapes
                      } else if(feature.properties.SUB_NAME) {
                        layer.bindPopup(
                          '<div class="map-popup">' +
                          '<h4 class="watershed">' + feature.properties.SUB_NAME + '</h4>' +
                          ((feature.properties.SUB_NAME == "St. Johns") ? "<a href='/groups/st-johns-riverkeeper-st-johns-riverkeeper-team'>St. Johns Riverkeeper</a>" : "") +
                          '</div>'
                        );
                      }
                    }
                  }
                }).addTo(map);
                watershedCheckbox.prop('disabled', true);
                if(shpFileName == "america.zip") {
                  // Contiguous USA so we hide Alaska
                  var shpBounds = [
                    [18.005611, -124.626080], //Southwest
                    [48.987386, -62.361014]  //Northeast
                  ];
                } else {
                  var shpBounds = shpGeoJson.getBounds();
                }
                map.fitBounds(shpBounds);
                map.spin(false);
                watershedCheckbox.prop('disabled', false);
              });
            }
          });

          $('.map-filter-button').on('click', function(e){
            e.preventDefault();
            if(body.hasClass('has-filters')) {
              body.removeClass('has-filters');
            } else {
              body.addClass('has-filters');
            }
          });

          $('.map-sidebar-collapse').on('click', function(e){
            e.preventDefault();
            if(body.hasClass('close-sidebar')) {
              body.removeClass('close-sidebar');
            } else {
              body.addClass('close-sidebar');
            }
          });

          $('.close-filters').on('click', function(e){
            e.preventDefault();
            body.removeClass('has-filters');
            // if (!isMobile.any()) {
            //   map.panToOffset(map.getCenter(),[-370,0]);
            // }
          });

          $('.reset-filters').on('click', function(e){
            e.preventDefault();
            $('input[name="filter"], input[name="watershed"]').prop('checked', false);
            // Remove the current filtered layer
            map.removeLayer(currentLayer);
            // Remove any polygon layers
            polygonLayer.eachLayer(function (layer) {
              polygonLayer.removeLayer(layer);
            });
            // Remove watershed layers
            watershedLayer.clearLayers();
            // Add back all locations and set the current layer to this
            map.addLayer(allLocations);
            currentLayer = allLocations;
            if (!isMobile.any()) {
              //map.fitBounds(allLocations.getBounds(),{maxZoom: 10,paddingTopLeft:[350,0]});
              map.fitBounds(allLocations.getBounds(),{maxZoom: 10});
            } else {
              map.fitBounds(allLocations.getBounds(),{maxZoom: 10});
              body.removeClass('has-filters');
            }
            $(this).fadeOut();
          });
        }
      });

      // Search Control
      /*
      map.addControl(new L.Control.Search({
        url: 'https://nominatim.openstreetmap.org/search?format=json&q={s}',
        jsonpParam: 'json_callback',
        propertyName: 'display_name',
        propertyLoc: ['lat','lon'],
        markerLocation: true,
        collapsed: false,
        markerIcon: locationMarker,
        container: 'map-search'
      })
      .on('search_locationfound', function(e) {
        removeMarkers();
        console.log('found');
        var position = e.latlng;
        var marker = L.marker(e.latlng, {icon: locationMarker, draggable: 'false'});
        marker.addTo(map).bindPopup(
          '<div class="map-popup">' +
          '<h4>'+I18n.map.searched+'</h4>' + 
          '<a href="' + Routes.new_observation_path({lat: position.lat, lng: position.lng}) + '" class="button small observation" data-turbolinks="false">'+I18n.map.add_observation+'</a>' +
          //'<a href="' + Routes.new_issue_path({lat: position.lat, lng: position.lng}) + '" class="" data-turbolinks="false">'+I18n.map.report_issue+'</a>' +
          '</div>'
        ).openPopup();
        if (isMobile.any()) {
          map.panToOffset(map.getCenter(),[0,80]);
        }
      }));
      // Replace the search icons with feather
      $('.search-button').html(Icon('search'));
      $('.search-cancel').html(Icon('x'));
      */
    }

    // The map on the user page, group page or edit page
    let geoJsonLayers;
    if(isUser || isGroup || isGroupRegion) {
      // Lets not store this location in restoreview
      localStorage.removeItem('mapView');
      // Set our multiple markers
      var groupLocations = gon.group_locations,
          groupRegion = gon.group_region,
          markers = L.markerClusterGroup(),
          groupLatLngs = [],
          drawnItems = new L.FeatureGroup();
      $.each(groupLocations, function(_key, value) {
        var location = value.location,
            observationCount = value.observationCount,
            lat = location.lat,
            lng = location.lng;
        groupLatLngs.push([lat,lng]);
        var marker_points = new L.marker([lat,lng], {icon: locationMarker})
          .bindPopup(
            '<div class="map-popup">' +
            ((typeof(location.body_of_water) !== 'undefined') && (location.body_of_water !== null) ? "<h4>" + location.body_of_water + "</h4>" : "") +
            ((typeof(location.name) !== 'undefined') && (location.name !== null) ? "<strong>" + location.name + "</strong>" : "") +
            '<p>' + I18n.observation.contributed + ' ' + observationCount + ' ' + I18n.observation.multiple + '</p>' +
            '<strong><a href="' + Routes.location_path(location) + '" data-turbolinks="false">'+I18n.observation.view_summary+'</a></strong>' +
            '</div>'
          );
        markers.addLayer(marker_points);
      });
      if(groupLatLngs.length) {
        var bounds = new L.LatLngBounds(groupLatLngs);
        map.fitBounds(bounds);
        map.addLayer(markers);
      } else {
        // If no locations set group map to center of Atlantic Ocean zoomed out
        map.setView(new L.LatLng(35.746512, -39.462891), 1);
      }
      map.spin(false);
      
      // Add a layer to draw the polygon on
      map.addLayer(drawnItems);

      // Add the region polygon if it exists
      if(!$.isEmptyObject(groupRegion)) {
        geoJsonLayers = L.geoJson(groupRegion, {
          onEachFeature: function (feature, layer) {
            drawnItems.addLayer(layer);
          }
        });
      }
    }

    // Region map with drawing controls
    if(isGroupRegion) {
      function addFeatherIcons() {
        $('.leaflet-draw-draw-polygon').html(Icon('polygon', 20, 20));
        // $('.leaflet-draw-draw-circlemarker').html(Icon('circle', 20, 20));
        $('.leaflet-draw-draw-circlemarker').hide();
        $('.leaflet-draw-edit-edit').html(Icon('edit', 20, 20));
        $('.leaflet-draw-edit-remove').html(Icon('x', 20, 20));
      }

      // Initialise the FeatureGroup to store editable layers
      var saveButton = $('.save-region'),
          groupRegionLayer = groupRegion;

      // Hide the save button until they have editted or created a new polygon
      saveButton.hide();
      //Set up our drawing controls and other draw options
      var drawControl = new L.Control.Draw({
        edit: {
          featureGroup: drawnItems
          // poly: {
          //   allowIntersection: false
          // }
        },
        draw: {
          polyline: false,
          circle: false,
          marker: false,
          rectangle: false,
          polygon : {
            allowIntersection: false,
            showArea: true,
            repeatMode: false,
            drawError: {
              color: '#e1e100', // Color the shape will turn when intersects
              message: '<strong>Oh snap!<strong> you can\'t draw that!' // Message that will show when intersect
            }
          }
        }
      });
      map.addControl(drawControl);
      addFeatherIcons();

      // Bring these polygons to the front of the stack to edit and delete
      drawnItems.bringToFront();

      // If the group has a region already added remove drawing control
      // they should only be able to edit a polygon
      if(!$.isEmptyObject(groupRegion)) {
        drawControl.setDrawingOptions({
          polygon: false
        });
        map.fitBounds(geoJsonLayers.getBounds());
        map.removeControl(drawControl);
        map.addControl(drawControl);
        addFeatherIcons();
      }
      
      // Drawing function
      map.on("draw:created", function (e) {
        var layer = e.layer;
        groupRegionLayer = layer.toGeoJSON();
        drawnItems.addLayer(layer);
        drawControl.setDrawingOptions({
          polygon: false
        });
        // console.log(groupRegionLayer);
        map.removeControl(drawControl);
        map.addControl(drawControl);
        addFeatherIcons();
        saveButton.fadeIn();
      });

      // Editing function
      map.on("draw:edited", function (e) {
        var layers = e.layers;
        // console.log(layers)
        layers.eachLayer(function (layer) {
          groupRegionLayer = layer.toGeoJSON();
        });
        //groupRegionLayer = layers.toGeoJSON();
        addFeatherIcons();
        saveButton.fadeIn();
      });

      // Delete function
      map.on("draw:deleted", function (e) {
        groupRegionLayer = {};
        drawControl.setDrawingOptions({
          polygon: true
        });
        map.removeControl(drawControl);
        map.addControl(drawControl);
        addFeatherIcons();
        saveButton.fadeIn();
      });

      // Now submit the geoJSON object back to our controller to save to db
      $('.save-region').on('click', function(e){
        e.preventDefault();
        map.spin(true,spinOpts);
        //console.log('cleared', JSON.stringify(jsonRegion));
        $.ajax({
          type: "POST",
          url: Routes.edit_region_geojson_group_path(gon.group_id),
          data: { 'region' : JSON.stringify(groupRegionLayer) },
          cache: false,
          success: function(data) {
            map.spin(false);
            saveButton.fadeOut();
            $('<div class="callout notice success" data-closable>Your region was successfully saved.</div>').appendTo('body');
            hideNotices();
          },
          error: function() {
            map.spin(false);
            saveButton.fadeOut();
            $('<div class="callout notice alert" data-closable>Your region could not be saved, please try again.</div>').appendTo('body');
            hideNotices();
          }
        });
      });

    }
    
    // ----- Map Controls (Zoom, Search, Reset) -----
    
    // Reset Control 
    L.easyButton({
      position: 'bottomright', 
      leafletClasses: true,
      states:[{
        stateName: 'get-current',
        onClick: function(button, map){
          removeMarkers();
          map.locate();
        },
        title: 'Go to my current location',
        icon: 'fa'
      }]
    }).addTo(map);
    $('.get-current-active').html(Icon('crosshair', 20, 20, '#000000'));
    
    // Zoom Control
    map.addControl(L.control.zoom({position: 'bottomright'}));
    $('.leaflet-control-zoom-in').html(Icon('plus', 16, 16, '#000000'));
    $('.leaflet-control-zoom-out').html(Icon('minus', 16, 16, '#000000'));

    // Layer Control
    L.control.layers(baseMaps,null,{position: 'bottomright'}).addTo(map);
    $('.leaflet-control-layers-toggle').html(Icon('layers', 16, 16, '#000000'));

  }

  // Static maps

  // Add different colour markers based on the action
  var markColor = '#246EC0';
  if($body.hasClass('locations')) {
    markColor = '#246EC0';
  } else if($body.hasClass('observations')) {
    markColor = '#46B64B';
  } else if($body.hasClass('issues')) {
    markColor = '#D42626';
  }

  var staticMarkerSVG = `
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="40" viewBox="0 0 36 40">
      <defs>
        <filter id="map-marker-single-a" width="116.4%" height="113.6%" x="-8.2%" y="-6.8%" filterUnits="objectBoundingBox">
          <feGaussianBlur in="SourceGraphic" stdDeviation=".558"/>
        </filter>
      </defs>
      <path fill="#343434" d="M20.0844717,37.6101876 C26.8893853,31.5140251 30.2918422,26.6249092 30.2918422,22.9428399 C30.2918422,17.4197359 25.7218467,12.9423755 20.0844717,12.9423755 C14.4470966,12.9423755 9.87710115,17.4197359 9.87710115,22.9428399 C9.87710115,26.6249092 13.279558,31.5140251 20.0844717,37.6101876 Z" filter="url(#map-marker-single-a)" opacity=".226" transform="rotate(22 20.084 25.276)"/>
      <path fill="${markColor}" d="M15,36 C25,27.5228475 30,20.5228475 30,15 C30,6.71572875 23.2842712,0 15,0 C6.71572875,0 0,6.71572875 0,15 C0,20.5228475 5,27.5228475 15,36 Z"/>
      <circle cx="15" cy="15" r="8" fill="#FFF"/>
    </svg>
  `;

  var staticIconUrl = 'data:image/svg+xml;base64,' + btoa(staticMarkerSVG);

  var staticMarker = L.icon({
    iconUrl: staticIconUrl,
    iconSize: [34,40],
    iconAnchor: [17,40]
  });

  if(isPoiMap) {
    var poiMarker = L.icon({
      iconUrl: '/assets/poi_defined.png'
    });

    var upstreamMarker = L.icon({
      iconUrl: '/assets/upstream.png',
      iconSize: [16, 16]
    });

    var downstreamMarker = L.icon({
      iconUrl: '/assets/downstream.png',
      iconSize: [16, 16]
    });

    // Set up the POI map
    var poiMap = new L.map('map-static', {
      scrollWheelZoom: false,
      doubleClickZoom: false,
      zoomControl: true,
      touchZoom: false,
      dragging: true,
      boxZoom: false,
      layers: [tileMaps['' || "Outdoor"]]
    });

    poiMap.zoomControl.setPosition('bottomright');

    const poiMapMarker = L.marker([gon.loc_lat, gon.loc_lng], {
      icon: poiMarker,
      clickable: false
    }).addTo(poiMap);

    if (gon.upstream_loc_lat && gon.upstream_loc_lng) {
      L.marker([gon.upstream_loc_lat, gon.upstream_loc_lng], {
        icon: upstreamMarker,
        clickable: false
      }).addTo(poiMap);
    }

    if (gon.downstream_loc_lat && gon.downstream_loc_lng) {
      L.marker([gon.downstream_loc_lat, gon.downstream_loc_lng], {
        icon: downstreamMarker,
        clickable: false
      }).addTo(poiMap);
    }

    centerLeafletMapOnMarker(poiMap, poiMapMarker);
  }

  if(isStaticMap) {

    var locLat = gon.loc_lat,
        locLng = gon.loc_lng;

    // Set up the static map
    var staticMap = new L.map('map-static', {
      scrollWheelZoom: false,
      doubleClickZoom: false,
      zoomControl: true,
      touchZoom: false,
      dragging: true,
      boxZoom: false,
      layers: [tileMaps['' || "Outdoor"]]
    });

    staticMap.zoomControl.setPosition('bottomright');
    
    const staticMapMarker = L.marker([locLat, locLng], {
      icon: staticMarker,
      clickable: false
    }).addTo(staticMap);

    centerLeafletMapOnMarker(staticMap, staticMapMarker);

  }

  // Editing Location Maps

  if($('#edit-map').length) {

    var editLocLat = gon.loc_lat,
        editLocLng = gon.loc_lng,
        isEditLoc = $body.hasAnyClass('new edit update') ? true : false;

    // Set up the static map
    var editMap = L.map('edit-map', {
      zoom: 16,
      scrollWheelZoom: false,
      doubleClickZoom: false,
      zoomControl: isEditLoc,
      touchZoom: false,
      dragging: isEditLoc,
      boxZoom: false,
      layers: [tileMaps['' || "Outdoor"]]
    }).setView([editLocLat, editLocLng]);

    if(isEditLoc === true) {
      editMap.zoomControl.setPosition('bottomright');
    }

    const editMarker = L.marker([editLocLat, editLocLng], {
      icon: staticMarker,
      clickable: isEditLoc,
      draggable: isEditLoc
    }).addTo(editMap)
    .on('dragend', function(event){
      var editPosition = event.target.getLatLng();
      $('#location_lat').val(editPosition.lat);
      $('#location_lng').val(editPosition.lng);
    });

  }

};

document.addEventListener("turbolinks:load", function(e) {
  $('#show_points_of_interest').change(e => {
    if (e.target.checked) {
      showPoi();
    } else {
      hidePoi();
    }
  });

  mapReady();
}, false);


/* rails-erb-loader-dependencies ../config/routes */
var routes = {};

(function() {
  /*
File generated by js-routes 1.4.9
Based on Rails 6.0.3.6 routes of WaterrangersCom::Application
 */

(function() {
  var DeprecatedGlobbingBehavior, NodeTypes, ParameterMissing, ReservedOptions, SpecialOptionsKey, UriEncoderSegmentRegex, Utils, result, root,
    hasProp = {}.hasOwnProperty,
    slice = [].slice;

  root = typeof exports !== "undefined" && exports !== null ? exports : this;

  ParameterMissing = function(message, fileName, lineNumber) {
    var instance;
    instance = new Error(message, fileName, lineNumber);
    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(instance, Object.getPrototypeOf(this));
    } else {
      instance.__proto__ = this.__proto__;
    }
    if (Error.captureStackTrace) {
      Error.captureStackTrace(instance, ParameterMissing);
    }
    return instance;
  };

  ParameterMissing.prototype = Object.create(Error.prototype, {
    constructor: {
      value: Error,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });

  if (Object.setPrototypeOf) {
    Object.setPrototypeOf(ParameterMissing, Error);
  } else {
    ParameterMissing.__proto__ = Error;
  }

  NodeTypes = {"GROUP":1,"CAT":2,"SYMBOL":3,"OR":4,"STAR":5,"LITERAL":6,"SLASH":7,"DOT":8};

  DeprecatedGlobbingBehavior = false;

  SpecialOptionsKey = "_options";

  UriEncoderSegmentRegex = /[^a-zA-Z0-9\-\._~!\$&'\(\)\*\+,;=:@]/g;

  ReservedOptions = ['anchor', 'trailing_slash', 'subdomain', 'host', 'port', 'protocol'];

  Utils = {
    configuration: {
      prefix: "",
      default_url_options: {},
      special_options_key: "_options",
      serializer: null
    },
    default_serializer: function(object, prefix) {
      var element, i, j, key, len, prop, s;
      if (prefix == null) {
        prefix = null;
      }
      if (object == null) {
        return "";
      }
      if (!prefix && !(this.get_object_type(object) === "object")) {
        throw new Error("Url parameters should be a javascript hash");
      }
      s = [];
      switch (this.get_object_type(object)) {
        case "array":
          for (i = j = 0, len = object.length; j < len; i = ++j) {
            element = object[i];
            s.push(this.default_serializer(element, prefix + "[]"));
          }
          break;
        case "object":
          for (key in object) {
            if (!hasProp.call(object, key)) continue;
            prop = object[key];
            if ((prop == null) && (prefix != null)) {
              prop = "";
            }
            if (prop != null) {
              if (prefix != null) {
                key = prefix + "[" + key + "]";
              }
              s.push(this.default_serializer(prop, key));
            }
          }
          break;
        default:
          if (object != null) {
            s.push((encodeURIComponent(prefix.toString())) + "=" + (encodeURIComponent(object.toString())));
          }
      }
      if (!s.length) {
        return "";
      }
      return s.join("&");
    },
    serialize: function(object) {
      var custom_serializer;
      custom_serializer = this.configuration.serializer;
      if ((custom_serializer != null) && this.get_object_type(custom_serializer) === "function") {
        return custom_serializer(object);
      } else {
        return this.default_serializer(object);
      }
    },
    clean_path: function(path) {
      var last_index;
      path = path.split("://");
      last_index = path.length - 1;
      path[last_index] = path[last_index].replace(/\/+/g, "/");
      return path.join("://");
    },
    extract_options: function(number_of_params, args) {
      var last_el, options;
      last_el = args[args.length - 1];
      if ((args.length > number_of_params && last_el === void 0) || ((last_el != null) && "object" === this.get_object_type(last_el) && !this.looks_like_serialized_model(last_el))) {
        options = args.pop() || {};
        delete options[this.configuration.special_options_key];
        return options;
      } else {
        return {};
      }
    },
    looks_like_serialized_model: function(object) {
      return !object[this.configuration.special_options_key] && ("id" in object || "to_param" in object);
    },
    path_identifier: function(object) {
      var property;
      if (object === 0) {
        return "0";
      }
      if (!object) {
        return "";
      }
      property = object;
      if (this.get_object_type(object) === "object") {
        if ("to_param" in object) {
          if (object.to_param == null) {
            throw new ParameterMissing("Route parameter missing: to_param");
          }
          property = object.to_param;
        } else if ("id" in object) {
          if (object.id == null) {
            throw new ParameterMissing("Route parameter missing: id");
          }
          property = object.id;
        } else {
          property = object;
        }
        if (this.get_object_type(property) === "function") {
          property = property.call(object);
        }
      }
      return property.toString();
    },
    clone: function(obj) {
      var attr, copy, key;
      if ((obj == null) || "object" !== this.get_object_type(obj)) {
        return obj;
      }
      copy = obj.constructor();
      for (key in obj) {
        if (!hasProp.call(obj, key)) continue;
        attr = obj[key];
        copy[key] = attr;
      }
      return copy;
    },
    merge: function() {
      var tap, xs;
      xs = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      tap = function(o, fn) {
        fn(o);
        return o;
      };
      if ((xs != null ? xs.length : void 0) > 0) {
        return tap({}, function(m) {
          var j, k, len, results, v, x;
          results = [];
          for (j = 0, len = xs.length; j < len; j++) {
            x = xs[j];
            results.push((function() {
              var results1;
              results1 = [];
              for (k in x) {
                v = x[k];
                results1.push(m[k] = v);
              }
              return results1;
            })());
          }
          return results;
        });
      }
    },
    normalize_options: function(parts, required_parts, default_options, actual_parameters) {
      var i, j, key, len, options, part, parts_options, result, route_parts, url_parameters, use_all_parts, value;
      options = this.extract_options(parts.length, actual_parameters);
      if (actual_parameters.length > parts.length) {
        throw new Error("Too many parameters provided for path");
      }
      use_all_parts = actual_parameters.length > required_parts.length;
      parts_options = {};
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        use_all_parts = true;
        if (this.indexOf(parts, key) >= 0) {
          parts_options[key] = value;
        }
      }
      options = this.merge(this.configuration.default_url_options, default_options, options);
      result = {};
      url_parameters = {};
      result['url_parameters'] = url_parameters;
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        value = options[key];
        if (this.indexOf(ReservedOptions, key) >= 0) {
          result[key] = value;
        } else {
          url_parameters[key] = value;
        }
      }
      route_parts = use_all_parts ? parts : required_parts;
      i = 0;
      for (j = 0, len = route_parts.length; j < len; j++) {
        part = route_parts[j];
        if (i < actual_parameters.length) {
          if (!parts_options.hasOwnProperty(part)) {
            url_parameters[part] = actual_parameters[i];
            ++i;
          }
        }
      }
      return result;
    },
    build_route: function(parts, required_parts, default_options, route, full_url, args) {
      var options, parameters, result, url, url_params;
      args = Array.prototype.slice.call(args);
      options = this.normalize_options(parts, required_parts, default_options, args);
      parameters = options['url_parameters'];
      result = "" + (this.get_prefix()) + (this.visit(route, parameters));
      url = Utils.clean_path(result);
      if (options['trailing_slash'] === true) {
        url = url.replace(/(.*?)[\/]?$/, "$1/");
      }
      if ((url_params = this.serialize(parameters)).length) {
        url += "?" + url_params;
      }
      url += options.anchor ? "#" + options.anchor : "";
      if (full_url) {
        url = this.route_url(options) + url;
      }
      return url;
    },
    visit: function(route, parameters, optional) {
      var left, left_part, right, right_part, type, value;
      if (optional == null) {
        optional = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return this.visit(left, parameters, true);
        case NodeTypes.STAR:
          return this.visit_globbing(left, parameters, true);
        case NodeTypes.LITERAL:
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
          return left;
        case NodeTypes.CAT:
          left_part = this.visit(left, parameters, optional);
          right_part = this.visit(right, parameters, optional);
          if (optional && ((this.is_optional_node(left[0]) && !left_part) || ((this.is_optional_node(right[0])) && !right_part))) {
            return "";
          }
          return "" + left_part + right_part;
        case NodeTypes.SYMBOL:
          value = parameters[left];
          delete parameters[left];
          if (value != null) {
            return this.encode_segment(this.path_identifier(value));
          }
          if (optional) {
            return "";
          } else {
            throw new ParameterMissing("Route parameter missing: " + left);
          }
          break;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    encode_segment: function(segment) {
      return segment.replace(UriEncoderSegmentRegex, function(str) {
        return encodeURIComponent(str);
      });
    },
    is_optional_node: function(node) {
      return this.indexOf([NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT], node) >= 0;
    },
    build_path_spec: function(route, wildcard) {
      var left, right, type;
      if (wildcard == null) {
        wildcard = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return "(" + (this.build_path_spec(left)) + ")";
        case NodeTypes.CAT:
          return "" + (this.build_path_spec(left)) + (this.build_path_spec(right));
        case NodeTypes.STAR:
          return this.build_path_spec(left, true);
        case NodeTypes.SYMBOL:
          if (wildcard === true) {
            return "" + (left[0] === '*' ? '' : '*') + left;
          } else {
            return ":" + left;
          }
          break;
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
        case NodeTypes.LITERAL:
          return left;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    visit_globbing: function(route, parameters, optional) {
      var left, right, type, value;
      type = route[0], left = route[1], right = route[2];
      value = parameters[left];
      delete parameters[left];
      if (value == null) {
        return this.visit(route, parameters, optional);
      }
      value = (function() {
        switch (this.get_object_type(value)) {
          case "array":
            return value.join("/");
          default:
            return value;
        }
      }).call(this);
      if (DeprecatedGlobbingBehavior) {
        return this.path_identifier(value);
      } else {
        return encodeURI(this.path_identifier(value));
      }
    },
    get_prefix: function() {
      var prefix;
      prefix = this.configuration.prefix;
      if (prefix !== "") {
        prefix = (prefix.match("/$") ? prefix : prefix + "/");
      }
      return prefix;
    },
    route: function(parts_table, default_options, route_spec, full_url) {
      var j, len, part, parts, path_fn, ref, required, required_parts;
      required_parts = [];
      parts = [];
      for (j = 0, len = parts_table.length; j < len; j++) {
        ref = parts_table[j], part = ref[0], required = ref[1];
        parts.push(part);
        if (required) {
          required_parts.push(part);
        }
      }
      path_fn = function() {
        return Utils.build_route(parts, required_parts, default_options, route_spec, full_url, arguments);
      };
      path_fn.required_params = required_parts;
      path_fn.toString = function() {
        return Utils.build_path_spec(route_spec);
      };
      return path_fn;
    },
    route_url: function(route_defaults) {
      var hostname, port, protocol, subdomain;
      if (typeof route_defaults === 'string') {
        return route_defaults;
      }
      hostname = route_defaults.host || Utils.current_host();
      if (!hostname) {
        return '';
      }
      subdomain = route_defaults.subdomain ? route_defaults.subdomain + '.' : '';
      protocol = route_defaults.protocol || Utils.current_protocol();
      port = route_defaults.port || (!route_defaults.host ? Utils.current_port() : void 0);
      port = port ? ":" + port : '';
      return protocol + "://" + subdomain + hostname + port;
    },
    has_location: function() {
      return (typeof window !== "undefined" && window !== null ? window.location : void 0) != null;
    },
    current_host: function() {
      if (this.has_location()) {
        return window.location.hostname;
      } else {
        return null;
      }
    },
    current_protocol: function() {
      if (this.has_location() && window.location.protocol !== '') {
        return window.location.protocol.replace(/:$/, '');
      } else {
        return 'http';
      }
    },
    current_port: function() {
      if (this.has_location() && window.location.port !== '') {
        return window.location.port;
      } else {
        return '';
      }
    },
    _classToTypeCache: null,
    _classToType: function() {
      var j, len, name, ref;
      if (this._classToTypeCache != null) {
        return this._classToTypeCache;
      }
      this._classToTypeCache = {};
      ref = "Boolean Number String Function Array Date RegExp Object Error".split(" ");
      for (j = 0, len = ref.length; j < len; j++) {
        name = ref[j];
        this._classToTypeCache["[object " + name + "]"] = name.toLowerCase();
      }
      return this._classToTypeCache;
    },
    get_object_type: function(obj) {
      if (root.jQuery && (root.jQuery.type != null)) {
        return root.jQuery.type(obj);
      }
      if (obj == null) {
        return "" + obj;
      }
      if (typeof obj === "object" || typeof obj === "function") {
        return this._classToType()[Object.prototype.toString.call(obj)] || "object";
      } else {
        return typeof obj;
      }
    },
    indexOf: function(array, element) {
      if (Array.prototype.indexOf) {
        return array.indexOf(element);
      } else {
        return this.indexOfImplementation(array, element);
      }
    },
    indexOfImplementation: function(array, element) {
      var el, i, j, len, result;
      result = -1;
      for (i = j = 0, len = array.length; j < len; i = ++j) {
        el = array[i];
        if (el === element) {
          result = i;
        }
      }
      return result;
    },
    namespace: function(root, namespace, routes) {
      var index, j, len, part, parts;
      parts = namespace ? namespace.split(".") : [];
      if (parts.length === 0) {
        return routes;
      }
      for (index = j = 0, len = parts.length; j < len; index = ++j) {
        part = parts[index];
        if (index < parts.length - 1) {
          root = (root[part] || (root[part] = {}));
        } else {
          return root[part] = routes;
        }
      }
    },
    configure: function(new_config) {
      return this.configuration = this.merge(this.configuration, new_config);
    },
    config: function() {
      return this.clone(this.configuration);
    },
    make: function() {
      var routes;
      routes = {
// about_group => /groups/:id/about(.:format)
  // function(id, options)
  about_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"about",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// accept_api_user_invitation => /api/auth/invitation/accept(.:format)
  // function(options)
  accept_api_user_invitation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"invitation",false],[2,[7,"/",false],[2,[6,"accept",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// accept_api_v1_user_invitation => /api/v1/auth/invitation/accept(.:format)
  // function(options)
  accept_api_v1_user_invitation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"invitation",false],[2,[7,"/",false],[2,[6,"accept",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// accept_api_v2_user_invitation => /api/v2/auth/invitation/accept(.:format)
  // function(options)
  accept_api_v2_user_invitation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"invitation",false],[2,[7,"/",false],[2,[6,"accept",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// accept_tos => /users/:id/accept_tos(.:format)
  // function(id, options)
  accept_tos_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"accept_tos",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// accept_user_invitation => /users/invitation/accept(.:format)
  // function(options)
  accept_user_invitation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"invitation",false],[2,[7,"/",false],[2,[6,"accept",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_activity => /api/activity(.:format)
  // function(options)
  api_activity_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"activity",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_auth_failure => /api/auth/failure(.:format)
  // function(options)
  api_auth_failure_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"failure",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_auth_validate_token => /api/auth/validate_token(.:format)
  // function(options)
  api_auth_validate_token_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"validate_token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_custom_form => /api/groups/:id/custom_form(.:format)
  // function(id, options)
  api_custom_form_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"custom_form",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_group => /api/groups/:id(.:format)
  // function(id, options)
  api_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_group_locations => /api/groups/:id/locations(.:format)
  // function(id, options)
  api_group_locations_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"locations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_groups => /api/groups(.:format)
  // function(options)
  api_groups_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_issue => /api/issues/:id(.:format)
  // function(id, options)
  api_issue_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_issue_image => /api/issues/:issue_id/images/:id(.:format)
  // function(issue_id, id, options)
  api_issue_image_path: Utils.route([["issue_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"issue_id",false],[2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_issue_images => /api/issues/:issue_id/images(.:format)
  // function(issue_id, options)
  api_issue_images_path: Utils.route([["issue_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"issue_id",false],[2,[7,"/",false],[2,[6,"images",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_issues => /api/issues(.:format)
  // function(options)
  api_issues_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"issues",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_location => /api/locations/:id(.:format)
  // function(id, options)
  api_location_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_location_observations => /api/locations/:location_id/observations(.:format)
  // function(location_id, options)
  api_location_observations_path: Utils.route([["location_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[3,"location_id",false],[2,[7,"/",false],[2,[6,"observations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_locations => /api/locations(.:format)
  // function(options)
  api_locations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"locations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_lookup => /api/lookups/:id(.:format)
  // function(id, options)
  api_lookup_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"lookups",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_observation => /api/observations/:id(.:format)
  // function(id, options)
  api_observation_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"observations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_observation_image => /api/observations/:observation_id/images/:id(.:format)
  // function(observation_id, id, options)
  api_observation_image_path: Utils.route([["observation_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"observations",false],[2,[7,"/",false],[2,[3,"observation_id",false],[2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_observation_images => /api/observations/:observation_id/images(.:format)
  // function(observation_id, options)
  api_observation_images_path: Utils.route([["observation_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"observations",false],[2,[7,"/",false],[2,[3,"observation_id",false],[2,[7,"/",false],[2,[6,"images",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_observation_test_strip => /api/observations/:observation_id/test_strips/:id(.:format)
  // function(observation_id, id, options)
  api_observation_test_strip_path: Utils.route([["observation_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"observations",false],[2,[7,"/",false],[2,[3,"observation_id",false],[2,[7,"/",false],[2,[6,"test_strips",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_observation_test_strips => /api/observations/:observation_id/test_strips(.:format)
  // function(observation_id, options)
  api_observation_test_strips_path: Utils.route([["observation_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"observations",false],[2,[7,"/",false],[2,[3,"observation_id",false],[2,[7,"/",false],[2,[6,"test_strips",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_observations => /api/observations(.:format)
  // function(options)
  api_observations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"observations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_tests => /api/tests/:id(.:format)
  // function(id, options)
  api_tests_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"tests",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_tests_index => /api/tests(.:format)
  // function(options)
  api_tests_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"tests",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_user_confirmation => /api/auth/confirmation(.:format)
  // function(options)
  api_user_confirmation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"confirmation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_user_invitation => /api/auth/invitation(.:format)
  // function(options)
  api_user_invitation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"invitation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_user_locations => /api/users/:id/locations(.:format)
  // function(id, options)
  api_user_locations_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"locations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_user_password => /api/auth/password(.:format)
  // function(options)
  api_user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_user_registration => /api/auth(.:format)
  // function(options)
  api_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"auth",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_user_session => /api/auth/sign_in(.:format)
  // function(options)
  api_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_users_profile => /api/users/profile(.:format)
  // function(options)
  api_users_profile_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"profile",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_activity => /api/v1/activity(.:format)
  // function(options)
  api_v1_activity_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"activity",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_auth_failure => /api/v1/auth/failure(.:format)
  // function(options)
  api_v1_auth_failure_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"failure",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v1_auth_validate_token => /api/v1/auth/validate_token(.:format)
  // function(options)
  api_v1_auth_validate_token_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"validate_token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v1_custom_form => /api/v1/groups/:id/custom_form(.:format)
  // function(id, options)
  api_v1_custom_form_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"custom_form",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v1_group => /api/v1/groups/:id(.:format)
  // function(id, options)
  api_v1_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v1_group_locations => /api/v1/groups/:id/locations(.:format)
  // function(id, options)
  api_v1_group_locations_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"locations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v1_groups => /api/v1/groups(.:format)
  // function(options)
  api_v1_groups_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_issue => /api/v1/issues/:id(.:format)
  // function(id, options)
  api_v1_issue_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v1_issue_image => /api/v1/issues/:issue_id/images/:id(.:format)
  // function(issue_id, id, options)
  api_v1_issue_image_path: Utils.route([["issue_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"issue_id",false],[2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// api_v1_issue_images => /api/v1/issues/:issue_id/images(.:format)
  // function(issue_id, options)
  api_v1_issue_images_path: Utils.route([["issue_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"issue_id",false],[2,[7,"/",false],[2,[6,"images",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v1_issues => /api/v1/issues(.:format)
  // function(options)
  api_v1_issues_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"issues",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_location => /api/v1/locations/:id(.:format)
  // function(id, options)
  api_v1_location_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v1_location_observations => /api/v1/locations/:location_id/observations(.:format)
  // function(location_id, options)
  api_v1_location_observations_path: Utils.route([["location_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[3,"location_id",false],[2,[7,"/",false],[2,[6,"observations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v1_locations => /api/v1/locations(.:format)
  // function(options)
  api_v1_locations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"locations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_lookup => /api/v1/lookups/:id(.:format)
  // function(id, options)
  api_v1_lookup_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"lookups",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v1_observation => /api/v1/observations/:id(.:format)
  // function(id, options)
  api_v1_observation_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"observations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v1_observation_image => /api/v1/observations/:observation_id/images/:id(.:format)
  // function(observation_id, id, options)
  api_v1_observation_image_path: Utils.route([["observation_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"observations",false],[2,[7,"/",false],[2,[3,"observation_id",false],[2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// api_v1_observation_images => /api/v1/observations/:observation_id/images(.:format)
  // function(observation_id, options)
  api_v1_observation_images_path: Utils.route([["observation_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"observations",false],[2,[7,"/",false],[2,[3,"observation_id",false],[2,[7,"/",false],[2,[6,"images",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v1_observation_test_strip => /api/v1/observations/:observation_id/test_strips/:id(.:format)
  // function(observation_id, id, options)
  api_v1_observation_test_strip_path: Utils.route([["observation_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"observations",false],[2,[7,"/",false],[2,[3,"observation_id",false],[2,[7,"/",false],[2,[6,"test_strips",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// api_v1_observation_test_strips => /api/v1/observations/:observation_id/test_strips(.:format)
  // function(observation_id, options)
  api_v1_observation_test_strips_path: Utils.route([["observation_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"observations",false],[2,[7,"/",false],[2,[3,"observation_id",false],[2,[7,"/",false],[2,[6,"test_strips",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v1_observations => /api/v1/observations(.:format)
  // function(options)
  api_v1_observations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"observations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_tests => /api/v1/tests/:id(.:format)
  // function(id, options)
  api_v1_tests_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"tests",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v1_tests_index => /api/v1/tests(.:format)
  // function(options)
  api_v1_tests_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"tests",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_user_confirmation => /api/v1/auth/confirmation(.:format)
  // function(options)
  api_v1_user_confirmation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"confirmation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v1_user_invitation => /api/v1/auth/invitation(.:format)
  // function(options)
  api_v1_user_invitation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"invitation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v1_user_locations => /api/v1/users/:id/locations(.:format)
  // function(id, options)
  api_v1_user_locations_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"locations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v1_user_password => /api/v1/auth/password(.:format)
  // function(options)
  api_v1_user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v1_user_registration => /api/v1/auth(.:format)
  // function(options)
  api_v1_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"auth",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_user_session => /api/v1/auth/sign_in(.:format)
  // function(options)
  api_v1_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v1_users_profile => /api/v1/users/profile(.:format)
  // function(options)
  api_v1_users_profile_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"profile",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v1_versions => /api/v1/versions(.:format)
  // function(options)
  api_v1_versions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"versions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v2_activity => /api/v2/activity(.:format)
  // function(options)
  api_v2_activity_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"activity",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v2_auth_failure => /api/v2/auth/failure(.:format)
  // function(options)
  api_v2_auth_failure_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"failure",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v2_auth_validate_token => /api/v2/auth/validate_token(.:format)
  // function(options)
  api_v2_auth_validate_token_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"validate_token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v2_custom_form => /api/v2/groups/:id/custom_form(.:format)
  // function(id, options)
  api_v2_custom_form_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"custom_form",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v2_group => /api/v2/groups/:id(.:format)
  // function(id, options)
  api_v2_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v2_group_locations => /api/v2/groups/:id/locations(.:format)
  // function(id, options)
  api_v2_group_locations_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"locations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v2_groups => /api/v2/groups(.:format)
  // function(options)
  api_v2_groups_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v2_issue => /api/v2/issues/:id(.:format)
  // function(id, options)
  api_v2_issue_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v2_issue_image => /api/v2/issues/:issue_id/images/:id(.:format)
  // function(issue_id, id, options)
  api_v2_issue_image_path: Utils.route([["issue_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"issue_id",false],[2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// api_v2_issue_images => /api/v2/issues/:issue_id/images(.:format)
  // function(issue_id, options)
  api_v2_issue_images_path: Utils.route([["issue_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"issue_id",false],[2,[7,"/",false],[2,[6,"images",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v2_issues => /api/v2/issues(.:format)
  // function(options)
  api_v2_issues_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"issues",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v2_location => /api/v2/locations/:id(.:format)
  // function(id, options)
  api_v2_location_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v2_location_observations => /api/v2/locations/:location_id/observations(.:format)
  // function(location_id, options)
  api_v2_location_observations_path: Utils.route([["location_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[3,"location_id",false],[2,[7,"/",false],[2,[6,"observations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v2_locations => /api/v2/locations(.:format)
  // function(options)
  api_v2_locations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"locations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v2_lookup => /api/v2/lookups/:id(.:format)
  // function(id, options)
  api_v2_lookup_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"lookups",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v2_observation => /api/v2/observations/:id(.:format)
  // function(id, options)
  api_v2_observation_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"observations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v2_observation_image => /api/v2/observations/:observation_id/images/:id(.:format)
  // function(observation_id, id, options)
  api_v2_observation_image_path: Utils.route([["observation_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"observations",false],[2,[7,"/",false],[2,[3,"observation_id",false],[2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// api_v2_observation_images => /api/v2/observations/:observation_id/images(.:format)
  // function(observation_id, options)
  api_v2_observation_images_path: Utils.route([["observation_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"observations",false],[2,[7,"/",false],[2,[3,"observation_id",false],[2,[7,"/",false],[2,[6,"images",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v2_observation_test_strip => /api/v2/observations/:observation_id/test_strips/:id(.:format)
  // function(observation_id, id, options)
  api_v2_observation_test_strip_path: Utils.route([["observation_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"observations",false],[2,[7,"/",false],[2,[3,"observation_id",false],[2,[7,"/",false],[2,[6,"test_strips",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// api_v2_observation_test_strips => /api/v2/observations/:observation_id/test_strips(.:format)
  // function(observation_id, options)
  api_v2_observation_test_strips_path: Utils.route([["observation_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"observations",false],[2,[7,"/",false],[2,[3,"observation_id",false],[2,[7,"/",false],[2,[6,"test_strips",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v2_observations => /api/v2/observations(.:format)
  // function(options)
  api_v2_observations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"observations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v2_tests => /api/v2/tests/:id(.:format)
  // function(id, options)
  api_v2_tests_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"tests",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v2_tests_index => /api/v2/tests(.:format)
  // function(options)
  api_v2_tests_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"tests",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v2_user_confirmation => /api/v2/auth/confirmation(.:format)
  // function(options)
  api_v2_user_confirmation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"confirmation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v2_user_invitation => /api/v2/auth/invitation(.:format)
  // function(options)
  api_v2_user_invitation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"invitation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v2_user_locations => /api/v2/users/:id/locations(.:format)
  // function(id, options)
  api_v2_user_locations_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"locations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v2_user_password => /api/v2/auth/password(.:format)
  // function(options)
  api_v2_user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v2_user_registration => /api/v2/auth(.:format)
  // function(options)
  api_v2_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"auth",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v2_user_session => /api/v2/auth/sign_in(.:format)
  // function(options)
  api_v2_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v2_users_profile => /api/v2/users/profile(.:format)
  // function(options)
  api_v2_users_profile_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"profile",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v2_versions => /api/v2/versions(.:format)
  // function(options)
  api_v2_versions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"versions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_versions => /api/versions(.:format)
  // function(options)
  api_versions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"versions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// badge_info => /badge-info(.:format)
  // function(options)
  badge_info_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"badge-info",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// badges_help => /help/badges(.:format)
  // function(options)
  badges_help_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"help",false],[2,[7,"/",false],[2,[6,"badges",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// cancel_api_user_registration => /api/auth/cancel(.:format)
  // function(options)
  cancel_api_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// cancel_api_v1_user_registration => /api/v1/auth/cancel(.:format)
  // function(options)
  cancel_api_v1_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// cancel_api_v2_user_registration => /api/v2/auth/cancel(.:format)
  // function(options)
  cancel_api_v2_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// cancel_user_registration => /users/cancel(.:format)
  // function(options)
  cancel_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// change_locale => /change_locale/:locale(.:format)
  // function(locale, options)
  change_locale_path: Utils.route([["locale",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"change_locale",false],[2,[7,"/",false],[2,[3,"locale",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// comment => /comments/:id(.:format)
  // function(id, options)
  comment_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"comments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// comments => /comments(.:format)
  // function(options)
  comments_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"comments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// conditions => /conditions/:id(.:format)
  // function(id, options)
  conditions_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"conditions",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// conditions_index => /conditions(.:format)
  // function(options)
  conditions_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"conditions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// custom_form => /custom_form(.:format)
  // function(options)
  custom_form_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"custom_form",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// data_breakdown => /issues/:id/data-breakdown(.:format)
  // function(id, options)
  data_breakdown_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"data-breakdown",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// destroy_api_user_session => /api/auth/sign_out(.:format)
  // function(options)
  destroy_api_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"sign_out",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// destroy_api_v1_user_session => /api/v1/auth/sign_out(.:format)
  // function(options)
  destroy_api_v1_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"sign_out",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// destroy_api_v2_user_session => /api/v2/auth/sign_out(.:format)
  // function(options)
  destroy_api_v2_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"sign_out",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// destroy_user_session => /users/sign_out(.:format)
  // function(options)
  destroy_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_out",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// digest_update_user => /users/:id/digest_update(.:format)
  // function(id, options)
  digest_update_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"digest_update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// download_data_issue => /issues/:id/download-data(.:format)
  // function(id, options)
  download_data_issue_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"download-data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_api_group => /api/groups/:id/edit(.:format)
  // function(id, options)
  edit_api_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_api_issue => /api/issues/:id/edit(.:format)
  // function(id, options)
  edit_api_issue_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_api_issue_image => /api/issues/:issue_id/images/:id/edit(.:format)
  // function(issue_id, id, options)
  edit_api_issue_image_path: Utils.route([["issue_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"issue_id",false],[2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_api_location => /api/locations/:id/edit(.:format)
  // function(id, options)
  edit_api_location_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_api_observation => /api/observations/:id/edit(.:format)
  // function(id, options)
  edit_api_observation_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"observations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_api_observation_image => /api/observations/:observation_id/images/:id/edit(.:format)
  // function(observation_id, id, options)
  edit_api_observation_image_path: Utils.route([["observation_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"observations",false],[2,[7,"/",false],[2,[3,"observation_id",false],[2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_api_tests => /api/tests/:id/edit(.:format)
  // function(id, options)
  edit_api_tests_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"tests",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_api_user_password => /api/auth/password/edit(.:format)
  // function(options)
  edit_api_user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_api_user_registration => /api/auth/edit(.:format)
  // function(options)
  edit_api_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_api_v1_group => /api/v1/groups/:id/edit(.:format)
  // function(id, options)
  edit_api_v1_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_api_v1_issue => /api/v1/issues/:id/edit(.:format)
  // function(id, options)
  edit_api_v1_issue_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_api_v1_issue_image => /api/v1/issues/:issue_id/images/:id/edit(.:format)
  // function(issue_id, id, options)
  edit_api_v1_issue_image_path: Utils.route([["issue_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"issue_id",false],[2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// edit_api_v1_location => /api/v1/locations/:id/edit(.:format)
  // function(id, options)
  edit_api_v1_location_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_api_v1_observation => /api/v1/observations/:id/edit(.:format)
  // function(id, options)
  edit_api_v1_observation_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"observations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_api_v1_observation_image => /api/v1/observations/:observation_id/images/:id/edit(.:format)
  // function(observation_id, id, options)
  edit_api_v1_observation_image_path: Utils.route([["observation_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"observations",false],[2,[7,"/",false],[2,[3,"observation_id",false],[2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// edit_api_v1_tests => /api/v1/tests/:id/edit(.:format)
  // function(id, options)
  edit_api_v1_tests_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"tests",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_api_v1_user_password => /api/v1/auth/password/edit(.:format)
  // function(options)
  edit_api_v1_user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_api_v1_user_registration => /api/v1/auth/edit(.:format)
  // function(options)
  edit_api_v1_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_api_v2_group => /api/v2/groups/:id/edit(.:format)
  // function(id, options)
  edit_api_v2_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_api_v2_issue => /api/v2/issues/:id/edit(.:format)
  // function(id, options)
  edit_api_v2_issue_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_api_v2_issue_image => /api/v2/issues/:issue_id/images/:id/edit(.:format)
  // function(issue_id, id, options)
  edit_api_v2_issue_image_path: Utils.route([["issue_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"issue_id",false],[2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// edit_api_v2_location => /api/v2/locations/:id/edit(.:format)
  // function(id, options)
  edit_api_v2_location_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_api_v2_observation => /api/v2/observations/:id/edit(.:format)
  // function(id, options)
  edit_api_v2_observation_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"observations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_api_v2_observation_image => /api/v2/observations/:observation_id/images/:id/edit(.:format)
  // function(observation_id, id, options)
  edit_api_v2_observation_image_path: Utils.route([["observation_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"observations",false],[2,[7,"/",false],[2,[3,"observation_id",false],[2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// edit_api_v2_tests => /api/v2/tests/:id/edit(.:format)
  // function(id, options)
  edit_api_v2_tests_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"tests",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_api_v2_user_password => /api/v2/auth/password/edit(.:format)
  // function(options)
  edit_api_v2_user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_api_v2_user_registration => /api/v2/auth/edit(.:format)
  // function(options)
  edit_api_v2_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_comment => /comments/:id/edit(.:format)
  // function(id, options)
  edit_comment_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"comments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_conditions => /conditions/:id/edit(.:format)
  // function(id, options)
  edit_conditions_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"conditions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_custom_form_group => /groups/:id/edit_custom_form(.:format)
  // function(id, options)
  edit_custom_form_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit_custom_form",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_custom_form_user => /users/:id/edit_custom_form(.:format)
  // function(id, options)
  edit_custom_form_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit_custom_form",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_data_issue => /issues/:id/edit-data(.:format)
  // function(id, options)
  edit_data_issue_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit-data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_group => /groups/:id/edit(.:format)
  // function(id, options)
  edit_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_groups_user => /users/:id/edit_groups(.:format)
  // function(id, options)
  edit_groups_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit_groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_invasive_species => /invasive_species/:id/edit(.:format)
  // function(id, options)
  edit_invasive_species_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"invasive_species",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_issue => /issues/:id/edit(.:format)
  // function(id, options)
  edit_issue_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_issue_update => /issue_updates/:id/edit(.:format)
  // function(id, options)
  edit_issue_update_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"issue_updates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_location => /locations/:id/edit(.:format)
  // function(id, options)
  edit_location_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_member_group => /groups/:id/edit_member(.:format)
  // function(id, options)
  edit_member_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit_member",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_members_group => /groups/:id/edit_members(.:format)
  // function(id, options)
  edit_members_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit_members",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_observation => /observations/:id/edit(.:format)
  // function(id, options)
  edit_observation_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"observations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_profile_user => /users/:id/edit_profile(.:format)
  // function(id, options)
  edit_profile_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit_profile",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_rails_conductor_inbound_email => /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format)
  // function(id, options)
  edit_rails_conductor_inbound_email_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_region_geojson_group => /groups/:id/edit_region_geojson(.:format)
  // function(id, options)
  edit_region_geojson_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit_region_geojson",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_region_group => /groups/:id/edit_region(.:format)
  // function(id, options)
  edit_region_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit_region",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_settings_group => /groups/:id/edit_settings(.:format)
  // function(id, options)
  edit_settings_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit_settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_user_password => /users/password/edit(.:format)
  // function(options)
  edit_user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_user_registration => /users/edit(.:format)
  // function(options)
  edit_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// edit_wildlife => /wildlife/:id/edit(.:format)
  // function(id, options)
  edit_wildlife_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"wildlife",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// faq_help => /help/faq(.:format)
  // function(options)
  faq_help_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"help",false],[2,[7,"/",false],[2,[6,"faq",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// feedback => /feedback(.:format)
  // function(options)
  feedback_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"feedback",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// filtered_locations => /locations/filtered(.:format)
  // function(options)
  filtered_locations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[6,"filtered",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// finish_signup => /users/:id/finish_signup(.:format)
  // function(id, options)
  finish_signup_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"finish_signup",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// gallery_location => /locations/:id/gallery(.:format)
  // function(id, options)
  gallery_location_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"gallery",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// group => /groups/:id(.:format)
  // function(id, options)
  group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// group_custom_form => /groups/:group_id/custom_form(.:format)
  // function(group_id, options)
  group_custom_form_path: Utils.route([["group_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"group_id",false],[2,[7,"/",false],[2,[6,"custom_form",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// group_image => /groups/:id/image(.:format)
  // function(id, options)
  group_image_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"image",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// group_locations => /groups/:id/locations(.:format)
  // function(id, options)
  group_locations_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"locations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// group_state_province_options => /group_state_province_options(.:format)
  // function(options)
  group_state_province_options_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"group_state_province_options",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// groups => /groups(.:format)
  // function(options)
  groups_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// groups_update_user => /users/:id/groups_update(.:format)
  // function(id, options)
  groups_update_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"groups_update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// help_water_quality => /help/water_quality(.:format)
  // function(options)
  help_water_quality_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"help",false],[2,[7,"/",false],[2,[6,"water_quality",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// info => /users/:id(.:format)
  // function(id, options)
  info_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// info_about => /users/:id/about(.:format)
  // function(id, options)
  info_about_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"about",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// info_issues => /users/:id/issues(.:format)
  // function(id, options)
  info_issues_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"issues",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// info_observations => /users/:id/observations(.:format)
  // function(id, options)
  info_observations_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"observations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// invasive_species => /invasive_species/:id(.:format)
  // function(id, options)
  invasive_species_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"invasive_species",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// invasive_species_index => /invasive_species(.:format)
  // function(options)
  invasive_species_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"invasive_species",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// invite_members_group => /groups/:id/invite_members(.:format)
  // function(id, options)
  invite_members_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"invite_members",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// invite_members_group_path_group => /groups/:id/invite_members(.:format)
  // function(id, options)
  invite_members_group_path_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"invite_members",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// issue => /issues/:id(.:format)
  // function(id, options)
  issue_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// issue_update => /issue_updates/:id(.:format)
  // function(id, options)
  issue_update_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"issue_updates",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// issue_updates => /issue_updates(.:format)
  // function(options)
  issue_updates_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"issue_updates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// issues => /issues(.:format)
  // function(options)
  issues_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// issues_get_form => /issues/get_form(.:format)
  // function(options)
  issues_get_form_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"get_form",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// issues_group => /groups/:id/issues(.:format)
  // function(id, options)
  issues_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"issues",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// issues_location => /locations/:id/issues(.:format)
  // function(id, options)
  issues_location_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"issues",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// join_group => /groups/:id/join(.:format)
  // function(id, options)
  join_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"join",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// local_observations_issues => /local_observations_issues(.:format)
  // function(options)
  local_observations_issues_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"local_observations_issues",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// location => /locations/:id(.:format)
  // function(id, options)
  location_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// locations => /locations(.:format)
  // function(options)
  locations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"locations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// locations_group => /groups/:id/locations(.:format)
  // function(id, options)
  locations_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"locations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// login => /login(.:format)
  // function(options)
  login_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"login",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// logout => /logout(.:format)
  // function(options)
  logout_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"logout",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// map => /map(.:format)
  // function(options)
  map_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"map",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// members_group => /groups/:id/members(.:format)
  // function(id, options)
  members_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"members",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_api_group => /api/groups/new(.:format)
  // function(options)
  new_api_group_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_api_issue => /api/issues/new(.:format)
  // function(options)
  new_api_issue_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_api_issue_image => /api/issues/:issue_id/images/new(.:format)
  // function(issue_id, options)
  new_api_issue_image_path: Utils.route([["issue_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"issue_id",false],[2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_api_location => /api/locations/new(.:format)
  // function(options)
  new_api_location_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_api_observation => /api/observations/new(.:format)
  // function(options)
  new_api_observation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"observations",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_api_observation_image => /api/observations/:observation_id/images/new(.:format)
  // function(observation_id, options)
  new_api_observation_image_path: Utils.route([["observation_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"observations",false],[2,[7,"/",false],[2,[3,"observation_id",false],[2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_api_tests => /api/tests/new(.:format)
  // function(options)
  new_api_tests_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"tests",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_api_user_confirmation => /api/auth/confirmation/new(.:format)
  // function(options)
  new_api_user_confirmation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"confirmation",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_api_user_invitation => /api/auth/invitation/new(.:format)
  // function(options)
  new_api_user_invitation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"invitation",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_api_user_password => /api/auth/password/new(.:format)
  // function(options)
  new_api_user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_api_user_registration => /api/auth/sign_up(.:format)
  // function(options)
  new_api_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"sign_up",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_api_user_session => /api/auth/sign_in(.:format)
  // function(options)
  new_api_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_api_v1_group => /api/v1/groups/new(.:format)
  // function(options)
  new_api_v1_group_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_api_v1_issue => /api/v1/issues/new(.:format)
  // function(options)
  new_api_v1_issue_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_api_v1_issue_image => /api/v1/issues/:issue_id/images/new(.:format)
  // function(issue_id, options)
  new_api_v1_issue_image_path: Utils.route([["issue_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"issue_id",false],[2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// new_api_v1_location => /api/v1/locations/new(.:format)
  // function(options)
  new_api_v1_location_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_api_v1_observation => /api/v1/observations/new(.:format)
  // function(options)
  new_api_v1_observation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"observations",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_api_v1_observation_image => /api/v1/observations/:observation_id/images/new(.:format)
  // function(observation_id, options)
  new_api_v1_observation_image_path: Utils.route([["observation_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"observations",false],[2,[7,"/",false],[2,[3,"observation_id",false],[2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// new_api_v1_tests => /api/v1/tests/new(.:format)
  // function(options)
  new_api_v1_tests_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"tests",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_api_v1_user_confirmation => /api/v1/auth/confirmation/new(.:format)
  // function(options)
  new_api_v1_user_confirmation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"confirmation",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_api_v1_user_invitation => /api/v1/auth/invitation/new(.:format)
  // function(options)
  new_api_v1_user_invitation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"invitation",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_api_v1_user_password => /api/v1/auth/password/new(.:format)
  // function(options)
  new_api_v1_user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_api_v1_user_registration => /api/v1/auth/sign_up(.:format)
  // function(options)
  new_api_v1_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"sign_up",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_api_v1_user_session => /api/v1/auth/sign_in(.:format)
  // function(options)
  new_api_v1_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_api_v2_group => /api/v2/groups/new(.:format)
  // function(options)
  new_api_v2_group_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_api_v2_issue => /api/v2/issues/new(.:format)
  // function(options)
  new_api_v2_issue_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_api_v2_issue_image => /api/v2/issues/:issue_id/images/new(.:format)
  // function(issue_id, options)
  new_api_v2_issue_image_path: Utils.route([["issue_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"issue_id",false],[2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// new_api_v2_location => /api/v2/locations/new(.:format)
  // function(options)
  new_api_v2_location_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_api_v2_observation => /api/v2/observations/new(.:format)
  // function(options)
  new_api_v2_observation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"observations",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_api_v2_observation_image => /api/v2/observations/:observation_id/images/new(.:format)
  // function(observation_id, options)
  new_api_v2_observation_image_path: Utils.route([["observation_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"observations",false],[2,[7,"/",false],[2,[3,"observation_id",false],[2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// new_api_v2_tests => /api/v2/tests/new(.:format)
  // function(options)
  new_api_v2_tests_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"tests",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_api_v2_user_confirmation => /api/v2/auth/confirmation/new(.:format)
  // function(options)
  new_api_v2_user_confirmation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"confirmation",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_api_v2_user_invitation => /api/v2/auth/invitation/new(.:format)
  // function(options)
  new_api_v2_user_invitation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"invitation",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_api_v2_user_password => /api/v2/auth/password/new(.:format)
  // function(options)
  new_api_v2_user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_api_v2_user_registration => /api/v2/auth/sign_up(.:format)
  // function(options)
  new_api_v2_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"sign_up",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_api_v2_user_session => /api/v2/auth/sign_in(.:format)
  // function(options)
  new_api_v2_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_comment => /comments/new(.:format)
  // function(options)
  new_comment_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"comments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_conditions => /conditions/new(.:format)
  // function(options)
  new_conditions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"conditions",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_group => /groups/new(.:format)
  // function(options)
  new_group_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_invasive_species => /invasive_species/new(.:format)
  // function(options)
  new_invasive_species_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"invasive_species",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_issue => /issues/new(.:format)
  // function(options)
  new_issue_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_issue_update => /issue_updates/new(.:format)
  // function(options)
  new_issue_update_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"issue_updates",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_location => /locations/new(.:format)
  // function(options)
  new_location_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_observation => /observations/new(.:format)
  // function(options)
  new_observation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"observations",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_rails_conductor_inbound_email => /rails/conductor/action_mailbox/inbound_emails/new(.:format)
  // function(options)
  new_rails_conductor_inbound_email_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_user_confirmation => /users/confirmation/new(.:format)
  // function(options)
  new_user_confirmation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"confirmation",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_user_invitation => /users/invitation/new(.:format)
  // function(options)
  new_user_invitation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"invitation",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_user_password => /users/password/new(.:format)
  // function(options)
  new_user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_user_registration => /users/sign_up(.:format)
  // function(options)
  new_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_up",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_user_session => /users/sign_in(.:format)
  // function(options)
  new_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_wildlife => /wildlife/new(.:format)
  // function(options)
  new_wildlife_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"wildlife",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// notes_location => /locations/:id/notes(.:format)
  // function(id, options)
  notes_location_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"notes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// observation => /observations/:id(.:format)
  // function(id, options)
  observation_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"observations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// observation_image => /observations/:id/images(.:format)
  // function(id, options)
  observation_image_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"observations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"images",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// observations => /observations(.:format)
  // function(options)
  observations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"observations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// observations_get_form => /observations/get_form(.:format)
  // function(options)
  observations_get_form_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"observations",false],[2,[7,"/",false],[2,[6,"get_form",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// observations_group => /groups/:id/observations(.:format)
  // function(id, options)
  observations_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"observations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// observations_location => /locations/:id/observations(.:format)
  // function(id, options)
  observations_location_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"observations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// photos_group => /groups/:id/photos(.:format)
  // function(id, options)
  photos_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"photos",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// poi => /poi/:id(.:format)
  // function(id, options)
  poi_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"poi",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// poi_index => /poi(.:format)
  // function(options)
  poi_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"poi",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// privacy_policy => /privacy-policy(.:format)
  // function(options)
  privacy_policy_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"privacy-policy",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// profile => /users/profile(.:format)
  // function(options)
  profile_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"profile",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// profile_update => /users/profile/:id(.:format)
  // function(id, options)
  profile_update_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"profile",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// rails_blob_representation => /rails/active_storage/representations/:signed_blob_id/:variation_key/*filename(.:format)
  // function(signed_blob_id, variation_key, filename, options)
  rails_blob_representation_path: Utils.route([["signed_blob_id",true],["variation_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"representations",false],[2,[7,"/",false],[2,[3,"signed_blob_id",false],[2,[7,"/",false],[2,[3,"variation_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// rails_conductor_inbound_email => /rails/conductor/action_mailbox/inbound_emails/:id(.:format)
  // function(id, options)
  rails_conductor_inbound_email_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_conductor_inbound_email_reroute => /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format)
  // function(inbound_email_id, options)
  rails_conductor_inbound_email_reroute_path: Utils.route([["inbound_email_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[3,"inbound_email_id",false],[2,[7,"/",false],[2,[6,"reroute",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_conductor_inbound_emails => /rails/conductor/action_mailbox/inbound_emails(.:format)
  // function(options)
  rails_conductor_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_direct_uploads => /rails/active_storage/direct_uploads(.:format)
  // function(options)
  rails_direct_uploads_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"direct_uploads",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// rails_disk_service => /rails/active_storage/disk/:encoded_key/*filename(.:format)
  // function(encoded_key, filename, options)
  rails_disk_service_path: Utils.route([["encoded_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"disk",false],[2,[7,"/",false],[2,[3,"encoded_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_mailgun_inbound_emails => /rails/action_mailbox/mailgun/inbound_emails/mime(.:format)
  // function(options)
  rails_mailgun_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mailgun",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"mime",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_mandrill_inbound_emails => /rails/action_mailbox/mandrill/inbound_emails(.:format)
  // function(options)
  rails_mandrill_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mandrill",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_mandrill_inbound_health_check => /rails/action_mailbox/mandrill/inbound_emails(.:format)
  // function(options)
  rails_mandrill_inbound_health_check_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mandrill",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_postmark_inbound_emails => /rails/action_mailbox/postmark/inbound_emails(.:format)
  // function(options)
  rails_postmark_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"postmark",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_relay_inbound_emails => /rails/action_mailbox/relay/inbound_emails(.:format)
  // function(options)
  rails_relay_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"relay",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_sendgrid_inbound_emails => /rails/action_mailbox/sendgrid/inbound_emails(.:format)
  // function(options)
  rails_sendgrid_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"sendgrid",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_service_blob => /rails/active_storage/blobs/:signed_id/*filename(.:format)
  // function(signed_id, filename, options)
  rails_service_blob_path: Utils.route([["signed_id",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"blobs",false],[2,[7,"/",false],[2,[3,"signed_id",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// refile_app => /attachments
  // function(options)
  refile_app_path: Utils.route([], {}, [2,[7,"/",false],[6,"attachments",false]]),
// register => /register(.:format)
  // function(options)
  register_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"register",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// remove_api_user_invitation => /api/auth/invitation/remove(.:format)
  // function(options)
  remove_api_user_invitation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"invitation",false],[2,[7,"/",false],[2,[6,"remove",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// remove_api_v1_user_invitation => /api/v1/auth/invitation/remove(.:format)
  // function(options)
  remove_api_v1_user_invitation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"invitation",false],[2,[7,"/",false],[2,[6,"remove",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// remove_api_v2_user_invitation => /api/v2/auth/invitation/remove(.:format)
  // function(options)
  remove_api_v2_user_invitation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"invitation",false],[2,[7,"/",false],[2,[6,"remove",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// remove_user_invitation => /users/invitation/remove(.:format)
  // function(options)
  remove_user_invitation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"invitation",false],[2,[7,"/",false],[2,[6,"remove",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// report_generate_group => /groups/:id/report_generate(.:format)
  // function(id, options)
  report_generate_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"report_generate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// report_generate_group_path_group => /groups/:id/report_generate(.:format)
  // function(id, options)
  report_generate_group_path_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"report_generate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// reports_group => /groups/:id/reports(.:format)
  // function(id, options)
  reports_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// root => /
  // function(options)
  root_path: Utils.route([], {}, [7,"/",false]),
// terms_of_service => /terms-of-service(.:format)
  // function(options)
  terms_of_service_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"terms-of-service",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// test_strip_analysis => /test_strip_analysis(.:format)
  // function(options)
  test_strip_analysis_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"test_strip_analysis",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// test_strip_callback => /test_strip_callbacks/:id(.:format)
  // function(id, options)
  test_strip_callback_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"test_strip_callbacks",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// update_rails_disk_service => /rails/active_storage/disk/:encoded_token(.:format)
  // function(encoded_token, options)
  update_rails_disk_service_path: Utils.route([["encoded_token",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"disk",false],[2,[7,"/",false],[2,[3,"encoded_token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// update_settings_group => /groups/:id/update_settings(.:format)
  // function(id, options)
  update_settings_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update_settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// update_settings_group_path_group => /groups/:id/update_settings(.:format)
  // function(id, options)
  update_settings_group_path_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update_settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_apple_omniauth_authorize => /users/auth/apple(.:format)
  // function(options)
  user_apple_omniauth_authorize_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"apple",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_apple_omniauth_callback => /users/auth/apple/callback(.:format)
  // function(options)
  user_apple_omniauth_callback_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"apple",false],[2,[7,"/",false],[2,[6,"callback",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_confirmation => /users/confirmation(.:format)
  // function(options)
  user_confirmation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"confirmation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_facebook_omniauth_authorize => /users/auth/facebook(.:format)
  // function(options)
  user_facebook_omniauth_authorize_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"facebook",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_facebook_omniauth_callback => /users/auth/facebook/callback(.:format)
  // function(options)
  user_facebook_omniauth_callback_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"facebook",false],[2,[7,"/",false],[2,[6,"callback",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_google_oauth2_omniauth_authorize => /users/auth/google_oauth2(.:format)
  // function(options)
  user_google_oauth2_omniauth_authorize_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"google_oauth2",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_google_oauth2_omniauth_callback => /users/auth/google_oauth2/callback(.:format)
  // function(options)
  user_google_oauth2_omniauth_callback_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"google_oauth2",false],[2,[7,"/",false],[2,[6,"callback",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_invitation => /users/invitation(.:format)
  // function(options)
  user_invitation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"invitation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_password => /users/password(.:format)
  // function(options)
  user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_registration => /users(.:format)
  // function(options)
  user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// user_session => /users/sign_in(.:format)
  // function(options)
  user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_state_province_options => /user_state_province_options(.:format)
  // function(options)
  user_state_province_options_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"user_state_province_options",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// users_auth_failure => /users/auth/failure(.:format)
  // function(options)
  users_auth_failure_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"failure",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// users_auth_mobile => /users/auth/mobile(.:format)
  // function(options)
  users_auth_mobile_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"mobile",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// widgets_issue_load => /widgets/issue/load(.:format)
  // function(options)
  widgets_issue_load_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"widgets",false],[2,[7,"/",false],[2,[6,"issue",false],[2,[7,"/",false],[2,[6,"load",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// widgets_issue_new => /widgets/issue/new(.:format)
  // function(options)
  widgets_issue_new_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"widgets",false],[2,[7,"/",false],[2,[6,"issue",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// widgets_latest => /widgets/latest(.:format)
  // function(options)
  widgets_latest_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"widgets",false],[2,[7,"/",false],[2,[6,"latest",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// widgets_location => /widgets/location(.:format)
  // function(options)
  widgets_location_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"widgets",false],[2,[7,"/",false],[2,[6,"location",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// wildlife => /wildlife/:id(.:format)
  // function(id, options)
  wildlife_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"wildlife",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// wildlife_index => /wildlife(.:format)
  // function(options)
  wildlife_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"wildlife",false],[1,[2,[8,".",false],[3,"format",false]],false]]])}
;
      routes.configure = function(config) {
        return Utils.configure(config);
      };
      routes.config = function() {
        return Utils.config();
      };
      Object.defineProperty(routes, 'defaults', {
        get: function() {
          throw new Error("Routes" + ".defaults is removed. Use " + "Routes" + ".configure() instead.");
        },
        set: function(value) {}
      });
      routes.default_serializer = function(object, prefix) {
        return Utils.default_serializer(object, prefix);
      };
      return Utils.namespace(root, "Routes", routes);
    }
  };

  result = Utils.make();

  if (typeof define === "function" && define.amd) {
    define([], function() {
      return result;
    });
  }

  return result;

}).call(this);

}.call(routes));

export default routes.Routes;
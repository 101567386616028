$.fn.accordion = function(options) {
  var settings = $.extend({
    target: '.target', // The target item that accepts the click to open the accordion
    content: '.content',
    item: '.item',
    autoCollpase: true,
    showFirstItem: false,
    scrollTo: false
  }, options);
  return this.each(function() {
    var $this = $(this),
        item = $this.find(settings.item),
        content = item.find(settings.content),
        urlHash = location.hash.substring(1);
    $this.addClass('loaded');
    // Hide all the content boxes
    content.hide();
    if(settings.showFirstItem) {
      item.first().addClass('active').find(content).show();
    }
    item.on('click', function(e){
      var $target = $(e.target),
          scrollPos = $target.position().top - 87;
      if($target.is(settings.target)) {
        // If the target isn't active, we can perform our animation
        if(!$(this).hasClass('active')) {
          if(settings.autoCollpase) {
            content.slideUp();
            item.removeClass("active");
          }
          $(this).find(content).slideToggle();
          $(this).addClass("active");
        } else {
          $(this).find(content).slideUp();
          $(this).removeClass("active");
        }
        if(settings.scrollTo) {
          $('.scroll-target').animate({
            scrollTop: scrollPos
          }, 500);
        }
      }
    });
  });
}